import { useState } from 'react'

import { Transition } from '@headlessui/react'
import { DocumentTextIcon } from '@heroicons/react/outline'

import nerveLogo from '@assets/icons/nerve.svg'
import telegramLogo from '@assets/icons/telegram.svg'
import discordLogo from '@assets/icons/discord.svg'

import NerveLogoSvg from '@components/icons/NerveLogoSvg'
import AddToWalletButton from '@components/AddToWalletButton'

import WalletManagement from '@layouts/WalletManagement'

import { NRV, NRV_IMG } from '@constants'


import {
  POOLS_PATH,
  BRIDGE_PATH,
  STAKE_PATH,
  XNRV_STAKING_PATH,
  CONTRACTS_PATH,
  PORTFOLIO_PATH,
  STATISTICS_PATH,
  PANCAKE_SWAP_NRV_BUSD_URL,
  NERVE_DOCS_URL,
  NERVE_DISCORD_URL,
  NERVE_TELEGRAM_URL,
  NERVE_FORUM_URL,
  NERVE_HOME_URL,
  NERVE_TWITTER_URL
} from '@urls'

import BridgeIcon from '@components/icons/BridgeIcon'
import ContractsIcon from '@components/icons/ContractsIcon'
import PoolsIcon from '@components/icons/PoolsIcon'
import StackIcon from '@components/icons/StackIcon'
import StakeIcon from '@components/icons/StakeIcon'
import DollarIcon from '@components/icons/DollarIcon'
import ChartBarIcon from '@components/icons/ChartBarIcon'
import ChartPieIcon from '@components/icons/ChartPieIcon'
import ForumIcon from '@components/icons/ForumIcon'
import TwitterIcon from '@components/icons/TwitterIcon'
import DiscordIcon from '@components/icons/DiscordIcon'
import TelegramIcon from '@components/icons/TelegramIcon'

import BottomNavFooterLink from './BottomNavFooterLink'
import CloseButton from './CloseButton'
import SidebarNavLink from './SidebarNavLink'
import OpenButton from './OpenButton'

import NervePriceBlock from './NervePriceBlock'



export default function PageWrapper({ children }) {
  const [open, setOpen] = useState(false)

  function onClick() {
    setOpen(!open)
  }

  const navBlock = (
    <div className='flex flex-col h-0 flex-1 '>
      <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto '>
        <a href={NERVE_HOME_URL}>
          <div className='flex items-center flex-shrink-0 px-4 py-1 '>
            <div className='mr-2'>
              <NerveLogoSvg />
            </div>
            <div className=' font-medium text-2xl tracking-wider pl-2 '>
              <span className='bg-clip-text  bg-gradient-to-r text-transparent from-purple-600 to-blue-600 hover:animate-pulse active:from-purple-700 active:to-blue-700 transform transition-all'>
                Nerve
              </span>
            </div>
          </div>
        </a>
        <nav className='mt-5 flex-1 px-2 space-y-1 text-default'>
          <div className='mb-4'>
            <WalletManagement />
          </div>
          <SidebarNavLink
            to='/'
            labelText='Swap'
            IconComponent={StackIcon}
          />
          <SidebarNavLink
            to={BRIDGE_PATH}
            labelText='Bridge'
            IconComponent={BridgeIcon}
          />
          <SidebarNavLink
            to={POOLS_PATH}
            labelText='Pools'
            IconComponent={PoolsIcon}
          />
          <SidebarNavLink
            to={STAKE_PATH}
            labelText='Stake'
            IconComponent={StakeIcon}
          />
          <SidebarNavLink
            to={XNRV_STAKING_PATH}
            labelText='xNRV'
            IconComponent={DollarIcon}
          />
          <SidebarNavLink
            to={CONTRACTS_PATH}
            labelText='Contract Info'
            IconComponent={ContractsIcon}
          />
          <SidebarNavLink
            to={PORTFOLIO_PATH}
            labelText='Portfolio'
            IconComponent={ChartPieIcon}
          />
          <SidebarNavLink
            to={STATISTICS_PATH}
            labelText='Statistics'
            IconComponent={ChartBarIcon}
          />
        </nav>
      </div>
      <div className='space-y-2 py-2 px-2'>
        <BottomNavFooterLink
          href={NERVE_DOCS_URL}
          labelText='Docs'
          icon={<DocumentTextIcon className='w-4 mr-1' />}
        />
        <BottomNavFooterLink
          href={NERVE_DISCORD_URL}
          labelText='Discord'
          icon={<DiscordIcon className='w-4 mr-1' />}
        />
        <BottomNavFooterLink
          href={NERVE_TELEGRAM_URL}
          labelText='Telegram'
          icon={<TelegramIcon className='w-4 mr-1' />}
        />
        <BottomNavFooterLink
          href={NERVE_TWITTER_URL}
          labelText='Twitter'
          icon={<TwitterIcon className='w-4 mr-1' />}
        />
        <BottomNavFooterLink
          href={NERVE_FORUM_URL}
          labelText='Forum'
          icon={<ForumIcon className='w-4 mr-1' />}
        />
        <BottomNavFooterLink
          href={PANCAKE_SWAP_NRV_BUSD_URL}
          labelText='Trade $NRV'
          icon={<img src={nerveLogo} className='w-4 mr-1' />}
        />
      </div>
      <div className='flex-shrink-0 flex border-t border-gray-200 p-4'>
        <a href='#' className='flex-shrink-0 w-full group block'>
          <div className='ml-2'>
            <div className='w-full'>
              <NervePriceBlock />
              <div className='inline-block float-right -mr-2'>
                <AddToWalletButton token={NRV} icon={NRV_IMG} />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  )

  return (
    <div className='h-screen flex overflow-hidden bg-gray-100'>
      <div
        className='static inset-0 flex z-40 md:hidden '
        role='dialog'
        aria-modal='true'
      >
        <Transition
          className='fixed inset-0 bg-gray-600 bg-opacity-75'
          aria-hidden='true'
          show={open}
          enter='transition-opacity duration-75'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-150'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        />

        <Transition
          className='relative flex-1 flex flex-col max-w-sm bg-white w-64'
          show={open}
          enter='transition ease-in-out duration-300 transform'
          enterFrom='-translate-x-full'
          enterTo='translate-x-0'
          leave='transition ease-in-out duration-300 transform'
          leaveFrom='translate-x-0'
          leaveTo='-translate-x-full'
        >
          <div className='absolute top-0 right-0 -mr-12 pt-2'>
            <CloseButton onClick={onClick} />
          </div>
          {navBlock}
        </Transition>
      </div>
      {/* Static sidebar for desktop */}
      <div className='hidden bg-white md:flex md:flex-shrink-0 shadow-sm'>
        <div className='flex flex-col w-64'>{navBlock}</div>
      </div>
      <div className='flex flex-col w-0 flex-1 overflow-hidden'>
        <div className='md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3'>
          <OpenButton onClick={onClick} />
        </div>
        <main className='flex-1 relative overflow-y-auto focus:outline-none'>
          {children}
        </main>
      </div>
    </div>
  )
}

