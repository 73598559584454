import { useMetaPoolData } from '@hooks/useMetaPoolData'
import { useApproveAndMetaDeposit } from '@hooks/useApproveAndMetaDeposit'
import { useApproveAndMetaWithdraw } from '@hooks/useApproveAndMetaWithdraw'
import { useMetaSwapDepositContract } from '@hooks/useContract'
import { useMetaWithdrawFormState } from '@hooks/useMetaWithdrawFormState'

import { useSharedPoolMagic } from '@hooks/useSharedPoolMagic'


export function useMetaSwapPoolMagic({ poolName }) {
  const approveAndMetaDeposit = useApproveAndMetaDeposit(poolName)
  const approveAndMetaWithdraw = useApproveAndMetaWithdraw(poolName)
  const swapContract = useMetaSwapDepositContract(poolName)
  const [poolData] = useMetaPoolData(poolName)

  const [withdrawFormState, updateWithdrawFormState] = useMetaWithdrawFormState(poolName)

  const sharedArgs = useSharedPoolMagic({
    poolName,
    poolData,
    swapContract,
    withdrawFormState,
    updateWithdrawFormState,
    approveAndDepositFunc: approveAndMetaDeposit,
    approveAndWithdrawFunc: approveAndMetaWithdraw
  })

  return {
    ...sharedArgs,

    poolData,
    updateWithdrawFormState,
    withdrawFormState,
  }

}
