import React, { useState } from 'react'

import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { setupNetwork } from '@utils/wallet'

import Modal from '@components/Modal'

import ConnectWallet from './ConnectWallet'
import WalletConnectButton from './WalletConnectButton'

import binanceLogo from '@assets/icons/binance.svg'
import ethLogo from '@assets/icons/eth.svg'
import { chain } from 'lodash'
import { useActiveWeb3React } from '@hooks'

export default function WalletManagement() {
  const { account, error, chainId } = useActiveWeb3React()
  const [showWalletModal, setShowWalletModal] = useState()
  const handleShow = () => setShowWalletModal(true)
  const handleClose = () => setShowWalletModal(false)

  const activeButtonClassname =
    'bg-indigo-700 hover:bg-indigo-600 hover:bg-opacity-75  focus:ring-0 focus:ring-indigo-500 '

  let chainLogo, chainSymbol
  if (chainId == 1) {
    chainSymbol = 'ETH'
    chainLogo = ethLogo
  } else if (chainId == 56) {
    chainSymbol = 'BSC'
    chainLogo = binanceLogo
  }

  if (account && !error) {
    var walletButton = (
      <WalletConnectButton
        className={`inline-block bg-indigo-700 hover:bg-indigo-800 active:bg-indigo-900 hover:shadow-indigo-lg`}
        onClick={handleShow}
      >
        <div>
          <div className='inline-block'>
            <img src={binanceLogo} className='inline-block' />
          </div>
          <div className='inline-block'>
            <h2 className='text-sm text-indigo-100'>
              Connected to {chainSymbol}{' '}
              <img src={chainLogo} className='inline-block w-4 h-4' />
            </h2>
            {account.substring(0, 6)}...
            {account.substring(account.length - 4, account.length)}
          </div>
        </div>
      </WalletConnectButton>
    )
  } else if (error) {
    var walletButton = (
      <WalletConnectButton
        onClick={async () => {
          if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await setupNetwork()
            if (hasSetup) {
              handleShow()
            }
          } else {
            console.log(error)
          }
        }}
        className=' bg-red-600 shadow-sm hover:bg-red-700 focus:outline-none focus:ring-0'
      >
        Wrong Network
      </WalletConnectButton>
    )
  } else {
    var walletButton = (
      <WalletConnectButton
        onClick={handleShow}
        className={`${activeButtonClassname} focus:ring-offset-2 shadow-indigo-sm `}
      >
        Connect Wallet
      </WalletConnectButton>
    )
  }

  return (
    <>
      <div className='flex items-center'>{walletButton}</div>
      <Modal isOpen={showWalletModal} onClose={handleClose}>
        <ConnectWallet onClose={handleClose} />
      </Modal>
    </>
  )
}
