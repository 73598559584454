
export default function BalanceInputContainer({ balanceStr, title, children, onClickBalance, className }) {
  return (
    <div className={`inline-block ${className}`}>
      <div>
        {title}
        <a onClick={onClickBalance} className="hover:underline group">
          <small className="inline-block float-right mt-1 text-gray-500 group-hover:underline cursor-pointer">
            Max:{' '}
            <span className="text-gray-800 font-medium ">
              {balanceStr}
              {/* {balance} */}
            </span>
          </small>
        </a>
      </div>
      {children}
    </div>
  )
}