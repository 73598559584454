import { useEffect, useState } from 'react'

import { Zero } from '@ethersproject/constants'

import { BigNumber } from '@ethersproject/bignumber'

import { formatUnits, parseUnits } from '@ethersproject/units'
import { useXNerveContract } from '@hooks/useContract'

import { useNrvPrice } from '@hooks/useNrvPrice'

export function useXNrvTvl() {
  const nrvPrice  = useNrvPrice()
  const xNerveContract = useXNerveContract()

  const [xNrvTotalSupply, setXNrvTotalSupply ] = useState(0)

  async function func() {
    const res = await xNerveContract.totalSupply()
    console.log( res)
    if (res.gt(0)) {
      setXNrvTotalSupply(res)
    }

  }

  useEffect(func, [xNerveContract, nrvPrice])


  if (xNrvTotalSupply == 0) {
    return {
      totalLockedUSD: 0
    }
  } else {
    const totalLockedUSD = Number(formatUnits(xNrvTotalSupply)) * nrvPrice
    console.log({ totalLockedUSD, nrvPrice})
    return ({
      totalLockedUSD: totalLockedUSD
    })
  }

}