import { debounce } from 'lodash'
import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'
import { useState, useCallback, useMemo } from 'react'

import { Link } from 'react-router-dom'

import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  SWAPABLE_TOKENS,
  NRVBTC_POOL_NAME,
  BITCOIN_POOL_TOKENS,
  POOLS_MAP,
  ANYBTC,
  BTCB,
  USDT,
  BUSD,
  USDC,
  ETH_POOL_TOKENS,
  NRVETH_POOL_NAME,
  ANYETH,
  ETHB,
  RUSD_POOL_TOKENS,
  RUSD_POOL_NAME,
  STABLE_SWAP_TOKEN,
} from '@constants'

import Grid from '@tw/Grid'

import { useSwapContract, useMasterSwapContract } from '@hooks/useContract'
import { usePoolData } from '@hooks/usePoolData'
import { usePoolTokenBalances } from '@hooks/useTokenBalances'

import { calculatePriceImpact } from '@utils/priceImpact'
import { calculateExchangeRate } from '@utils'

import PageWrapper from '@layouts/PageWrapper'

import SwapCard from './SwapCard'
import CoinSwapper from './CoinSwapper'

import { POOLS_PATH } from '@urls'

export default function SwapPage() {
  const [poolNameState, setPoolNameState] = useState(STABLECOIN_POOL_NAME)
  const swapContract = useMasterSwapContract(poolNameState)
  const tokenBalances = usePoolTokenBalances(poolNameState)
  const [poolData] = usePoolData(poolNameState)

  // build a representation of pool tokens for the UI
  const index = SWAPABLE_TOKENS.indexOf(STABLE_SWAP_TOKEN)
  let coins
  if (index > -1) {
    coins = SWAPABLE_TOKENS.splice(index, 1)
  } else {
    coins = SWAPABLE_TOKENS
  }

  const [formState, setFormState] = useState({
    from: {
      coin: SWAPABLE_TOKENS[0],
      value: '0',
    },
    to: {
      coin: SWAPABLE_TOKENS[1],
      value: BigNumber.from('0'),
    },
    priceImpact: BigNumber.from('0'),
    exchangeRate: BigNumber.from('0'),
  })

  function whichFromPoolSelected(coin) {
    if (BITCOIN_POOL_TOKENS.find(({ symbol }) => symbol === coin.symbol)) {
      setPoolNameState(NRVBTC_POOL_NAME)
      console.log(coin.symbol)
      if (coin.symbol == 'BTCB') {
        onSelectToCoin(ANYBTC, false)
      } else {
        onSelectToCoin(BTCB, false)
      }
    } else if (ETH_POOL_TOKENS.find(({ symbol }) => symbol === coin.symbol)) {
      setPoolNameState(NRVETH_POOL_NAME)
      console.log(coin.symbol)
      if (coin.symbol == 'ETH') {
        onSelectToCoin(ANYETH, false)
      } else {
        onSelectToCoin(ETHB, false)
      }
    } else if ('rUSD' === coin.symbol || 'rUSD' === formState.to.coin.symbol) {
      setPoolNameState(RUSD_POOL_NAME)
      console.log(coin.symbol)
      if (
        STABLECOIN_POOL_TOKENS.find(
          ({ symbol }) => symbol === formState.to.coin.symbol
        ) &&
        'rUSD' === formState.to.coin.symbol
      ) {
        onSelectFromCoin(coin, false)
        // already set, do nothing
      } else {
        onSelectFromCoin(BUSD, false)
      }
    } else if (
      STABLECOIN_POOL_TOKENS.find(({ symbol }) => symbol === coin.symbol)
    ) {
      setPoolNameState(STABLECOIN_POOL_NAME)
      if (
        !STABLECOIN_POOL_TOKENS.find(
          ({ symbol }) => symbol === formState.to.coin.symbol
        )
      ) {
        if (coin.symbol != 'USDT') {
          onSelectToCoin(USDT, false)
        } else if (coin.symbol != 'BUSD') {
          onSelectToCoin(BUSD, false)
        } else {
          onSelectToCoin(USDC, false)
        }
      }
    }
  }

  function whichToPoolSelected(coin) {
    if (BITCOIN_POOL_TOKENS.find(({ symbol }) => symbol === coin.symbol)) {
      setPoolNameState(NRVBTC_POOL_NAME)
      if (coin.symbol == 'BTCB') {
        console.log('picking anyBTC')
        onSelectFromCoin(ANYBTC, false)
      } else {
        onSelectFromCoin(BTCB, false)
      }
    } else if (ETH_POOL_TOKENS.find(({ symbol }) => symbol === coin.symbol)) {
      setPoolNameState(NRVETH_POOL_NAME)
      console.log(coin.symbol)
      if (coin.symbol == 'ETH') {
        onSelectFromCoin(ANYETH, false)
      } else {
        onSelectFromCoin(ETHB, false)
      }
    } else if (
      'rUSD' === coin.symbol ||
      'rUSD' === formState.from.coin.symbol
    ) {
      setPoolNameState(RUSD_POOL_NAME)
      console.log(coin.symbol)
      if (
        // if stablecoin is clicked to be to while rUSD is selected onf rom
        STABLECOIN_POOL_TOKENS.find(({ symbol }) => symbol === coin.symbol) &&
        'rUSD' === formState.from.coin.symbol
      ) {
        onSelectToCoin(coin, false)
      } else {
        onSelectToCoin(BUSD, false)
      }
    } else if (
      STABLECOIN_POOL_TOKENS.find(({ symbol }) => symbol === coin.symbol)
    ) {
      setPoolNameState(STABLECOIN_POOL_NAME)
      if (
        !STABLECOIN_POOL_TOKENS.find(
          ({ symbol }) => symbol === formState.from.coin.symbol
        )
      ) {
        if (coin.symbol != 'USDT') {
          onSelectFromCoin(USDT, false)
        } else if (coin.symbol != 'BUSD') {
          onSelectFromCoin(BUSD, false)
        } else {
          onSelectFromCoin(USDC, false)
        }
      }
    }
  }

  function swapFromToCoins(from, to) {
    setFormState((prevState) => {
      const nextState = {
        error: null,
        from: {
          coin: prevState.to.coin,
          value: prevState.from.value,
        },
        to: {
          coin: prevState.from.coin,
          value: BigNumber.from('0'),
        },
        priceImpact: BigNumber.from('0'),
        exchangeRate: BigNumber.from('0'),
      }
      void calculateSwapAmount(nextState)
      return nextState
    })
  }

  function onSelectFromCoin(coin, checkPool = true) {
    if (checkPool != false) {
      whichFromPoolSelected(coin)
    }

    if (coin.symbol == formState.to.coin.symbol) {
      swapFromToCoins(formState.from.coin, formState.to.coin)
    } else {
      setFormState((prevState) => {
        const nextState = {
          ...prevState,
          error: null,
          from: {
            ...prevState.from,
            coin,
          },
          to: {
            ...prevState.to,
            value: BigNumber.from('0'),
          },
          priceImpact: BigNumber.from('0'),
          exchangeRate: BigNumber.from('0'),
        }
        void calculateSwapAmount(nextState)
        return nextState
      })
    }
  }

  function onSelectToCoin(coin, checkPool = true) {
    if (checkPool != false) {
      whichToPoolSelected(coin)
    }

    if (coin.symbol == formState.from.coin.symbol) {
      swapFromToCoins(formState.from.coin, formState.to.coin)
    } else {
      setFormState((prevState) => {
        const nextState = {
          ...prevState,
          error: null,
          to: {
            ...prevState.to,
            value: BigNumber.from('0'),
            coin,
          },
          priceImpact: BigNumber.from('0'),
          exchangeRate: BigNumber.from('0'),
        }
        void calculateSwapAmount(nextState)
        return nextState
      })
    }
  }

  const calculateSwapAmount = useCallback(
    debounce(async (formStateArg) => {
      if (swapContract == null) return
      const cleanedFormFromValue = formStateArg.from.value.replace(/[$,]/g, '') // remove common copy/pasted financial characters
      if (cleanedFormFromValue === '' || isNaN(+cleanedFormFromValue)) {
        setFormState((prevState) => ({
          ...prevState,
          to: {
            ...prevState.to,
            value: BigNumber.from('0'),
          },
          priceImpact: BigNumber.from('0'),
        }))
        return
      }
      const tokenIndexFrom = POOLS_MAP[poolNameState].findIndex(
        ({ symbol }) => symbol === formStateArg.from.coin.symbol
      )
      const tokenIndexTo = POOLS_MAP[poolNameState].findIndex(
        ({ symbol }) => symbol === formStateArg.to.coin.symbol
      )

      const amountToGive = parseUnits(
        cleanedFormFromValue,
        formStateArg.from.coin.decimals
      )
      let error = null
      let amountToReceive

      if (amountToGive.gt(tokenBalances[formState.from.coin.symbol])) {
        formState.error = 'Insufficent Balance'
      }

      if (amountToGive.isZero()) {
        amountToReceive = BigNumber.from('0')
      } else if (
        formStateArg.from.coin.symbol == 'rUSD' ||
        formStateArg.to.coin.symbol == 'rUSD'
      ) {
        amountToReceive = await swapContract.calculateSwapUnderlying(
          tokenIndexFrom,
          tokenIndexTo,
          amountToGive
        )
      } else {
        amountToReceive = await swapContract.calculateSwap(
          tokenIndexFrom,
          tokenIndexTo,
          amountToGive
        )
      }

      const tokenTo = formStateArg.to.coin
      const tokenFrom = formStateArg.from.coin
      setFormState((prevState) => ({
        ...prevState,
        error,
        to: {
          ...prevState.to,
          value: amountToReceive,
        },
        priceImpact: calculatePriceImpact(
          amountToGive.mul(BigNumber.from(10).pow(18 - tokenFrom.decimals)),
          amountToReceive.mul(BigNumber.from(10).pow(18 - tokenTo.decimals)),
          poolData?.virtualPrice
        ),
        exchangeRate: calculateExchangeRate(
          amountToGive,
          tokenFrom.decimals,
          amountToReceive,
          tokenTo.decimals
        ),
      }))
      console.log(formState)
    }, 250),
    [setFormState, swapContract, tokenBalances, poolData]
  )

  function onChangeFromAmount(value) {
    console.log(value)
    setFormState((prevState) => {
      const nextState = {
        ...prevState,
        from: {
          ...prevState.from,
          value: value,
        },
        priceImpact: BigNumber.from('0'),
        exchangeRate: BigNumber.from('0'),
      }
      void calculateSwapAmount(nextState)
      return nextState
    })
  }

  return (
    <PageWrapper>
      <main className='relative z-0 overflow-y-auto focus:outline-none h-full'>
        <div className='py-6'>
          <Grid
            cols={{ xs: 1, sm: 2 }}
            gap={6}
            className='py-28 justify-center px-2 sm:px-6 md:px-8'
          >
            <div className='px-4'>
              <h1 className='text-default text-5xl font-medium'>
                I'd like to swap from
              </h1>
              <CoinSwapper
                {...{
                  formState,
                  coins,
                  onSelectFromCoin,
                  onSelectToCoin,
                  swapFromToCoins,
                }}
              />
              <div className='flex flex-col my-6'>
                <h3 className='text-lg text-default font-medium'>
                  About Nerve
                </h3>
                <div className='prose'>
                  Nerve is a trustless on-ramp and stableswap on the Binance
                  Smart Chain.
                </div>
                <div className='prose'>
                  Bridge assets onto BSC and earn yield on BTC, ETH, and
                  stablecoins
                </div>
                <div className='flex'>
                  <Link exact to='/pools'>
                    <button
                      type='button'
                      className='mt-4 px-4 py-3 border border-transparent text-base font-medium rounded-lg shadow-indigo-lg hover:shadow-indigo-xl text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 '
                    >
                      <span className=''>Explore Nerve Pools</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <SwapCard
                poolName={poolNameState}
                coins={coins}
                formState={formState}
                fromCoin={formState.from}
                toCoin={formState.to}
                onChangeFromToken={onSelectFromCoin}
                onChangeToToken={onSelectToCoin}
                onChangeFromAmount={onChangeFromAmount}
                onClickReverseExchangeDirection={swapFromToCoins}
              />
            </div>
          </Grid>
        </div>
      </main>
    </PageWrapper>
  )
}
