import classNames from 'classnames'


export default function TabItem({ onClick, children, isActive }) {
  return (
    <div
      onClick={onClick}
      className={
        classNames("px-4 py-2",
          "w-full",
          "text-center font-medium text-sm",
          "rounded-md place-self-center",
          "cursor-pointer",
          "transition-all duration-75",
          {
            " bg-gray-100 text-gray-700": isActive,
            "hover:bg-gray-200 text-gray-500 hover:text-gray-700": !isActive
          }
        )
      }
    >
      {children}
    </div>
  )
}