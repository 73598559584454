import classNames from 'classnames'

import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'




import Card from '@tw/Card'
import Grid from '@tw/Grid'



export default function StatCard({ label, content, IconComponent }) {
  return (
    <Card className="mt-0 sm:mt-4 md:mt-6 bg-gradient-to-b from-coolGray-900 to-coolGray-800 shadow-indigo-xl pt-5 pr-3 pl-4 sm:pr-6 sm:pl-4 pb-5 sm:pb-6 opacity-90">
        {/* <dt>
          <div className="absolute bg-indigo-500 rounded-md p-3">
            <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
        </dt> */}
        <div className="">
          <div className="hidden lg:inline-block h-full w-12 pb-4 ">

          <IconComponent className="h-8 w-8 text-indigo-200 " />

          </div>
          <div className="inline-block ">
            <dd className="text-4xl font-bold text-white">
              {content}

            </dd>
            <dt className="mt-2 text-lg leading-6 font-medium text-indigo-400">{label}</dt>
          </div>


        </div>


    </Card>
  )
}

function getChangeType(changeType) {
  return (
    changeType === 'increase' ? 'text-green-600' : 'text-red-600'
  )
}