import { useSwapPoolMagic } from '@hooks/useSwapPoolMagic'
import DepositDisplay from './DepositDisplay'


export default function PoolManagement({ poolName }) {
  const poolMagicProps = useSwapPoolMagic({ poolName })


  return (
    <DepositDisplay {...poolMagicProps}/>
  )
}
