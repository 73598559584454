import { Link } from 'react-router-dom'

import { POOLS_MAP, STAKING_MAP, INVERTED_STAKING_MAP_TOKENS } from '@constants'

import AddToWalletButton from '@components/AddToWalletButton'
import ExternalLinkButton from '@components/ExternalLinkButton'

import CopyableAddress from '@components/CopyableAddress'

import { useTokenBalance } from '@hooks/useTokenBalances'

import Grid from '@tw/Grid'

import BalanceSection from './BalanceSection'
import StakedBalanceSection from './StakedBalanceSection'


export default function PortfolioListItem({ token, stakeLinkTo, getBalanceLinkToUrl, getTitleLinkTo }) {
  const tokenBalance = useTokenBalance(token)

  const symbol = token.symbol
  const poolName = INVERTED_STAKING_MAP_TOKENS[token.symbol]

  let poolId, coins
  if (poolName) {
    poolId = STAKING_MAP[poolName]
    coins = POOLS_MAP[poolName]
  } else {
    poolId = -1
  }

  const balanceLinkTo = getBalanceLinkToUrl?.({token})

  // console.log({poolId, symbol, poolName })
  let iconDisplay
  if (coins) {
    iconDisplay = (
      <div className='inline-block ml-2 '>
        {coins.map((coin) => (
          <img
            className='relative -ml-2 inline-block text-white shadow-solid w-5 h-5 -mt-1'
            src={coin.icon}
          />
        ))}
      </div>
    )
  } else {
    iconDisplay = <img src={token.icon} className="w-5 h-5 inline mr-2 -mt-1" />
  }
  const symbolContent = (
    <span className='text-md font-medium mr-2'>
      {symbol}
    </span>
  )

  let symbolDisplay
  if (getTitleLinkTo) {
    const titleLinkClassName = "text-gray-900 hover:text-blue-500"
    const titleHref = getTitleLinkTo({ token })
    if (titleHref[0] == '/') {
      symbolDisplay =(
        <Link to={titleHref} className={titleLinkClassName}>
          {symbolContent}
        </Link>
      )
    } else {
      symbolDisplay = (
        <a href={titleHref} className={titleLinkClassName} target="_blank">
          {symbolContent}
        </a>
      )
    }
  } else {
    symbolDisplay = (
      <span className="text-gray-900">
        {symbolContent}
      </span>
    )
  }


  return (
    <li className='ml-auto py-2'>
      <Grid gap={2} cols={{xs: 1, sm: 2}}>
        <div className='self-center'>
          <div>
            {symbolDisplay}
            {iconDisplay}
          </div>
          <div>
            <small className="text-gray-400">
              {token.name}
            </small>
          </div>
        </div>
        <div className='h-full w-full self-center'>
          <div>
            <BalanceSection
              label="Total Balance"
              balance={tokenBalance}
              token={token}
              linkTo={balanceLinkTo}
            />
            {(poolId > -1) &&
              <StakedBalanceSection
                poolId={poolId}
                token={token}
                stakeLinkTo={stakeLinkTo}
              />
            }
          </div>
          {/* <div>
            {
              ( docUrl &&
                <ExternalLinkButton
                  href={docUrl}
                  text={`Open ${symbol} Docs`}
                  className='float-right'
                />
            )
            }
          </div> */}
        </div>
      </Grid>
    </li>
  )
}



