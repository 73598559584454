import {
  INVERTED_ROUTER_INDEX,
  INVERTED_STAKING_MAP_TOKENS,
  CAKE_LP_POOl_NAME,
  CAKE_LP_V2_POOl_NAME,
  CAKE_LP_V2_NEW_POOl_NAME,
} from '@constants'

// Hardcoding this shit for now until actual plan around routing
console.log(process.env?.NODE_ENV)
let NERVE_BASE_URL
if (process.env?.NODE_ENV === 'development') {
  NERVE_BASE_URL = 'http://localhost:3000'
} else {
  NERVE_BASE_URL = 'https://app.nerve.fi'
}

let NERVE_HOME_URL
if (process.env?.NODE_ENV === 'development') {
  NERVE_HOME_URL = 'http://localhost:3000/home'
} else {
  NERVE_HOME_URL = 'https://nerve.fi'
}
export { NERVE_BASE_URL, NERVE_HOME_URL }

export const STAKE_PATH = '/stake'
export const XNRV_STAKING_PATH = '/nrv'
export const POOLS_PATH = '/pools'
export const BRIDGE_PATH = '/bridge'
export const CONTRACTS_PATH = '/contracts'
export const PORTFOLIO_PATH = '/portfolio'
export const STATISTICS_PATH = '/statistics'

export const PANCAKE_SWAP_NRV_BUSD_URL =
  'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x42f6f551ae042cbe50c739158b4f0cac0edb9096&inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56'
export const PANCAKE_ADD_V2_LIQUIDITY_NRV_BUSD_URL =
  'https://exchange.pancakeswap.finance/#/add/0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'

export const PANCAKE_ADD_LIQUIDITY_NRV_BUSD_URL =
  'https://v1exchange.pancakeswap.finance/#/add/0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'

export function getPoolUrl({ symbol, token, poolName, poolRouterIndex }) {
  if (poolName == CAKE_LP_V2_NEW_POOl_NAME) {
    return PANCAKE_ADD_V2_LIQUIDITY_NRV_BUSD_URL
  }
  if (poolName == CAKE_LP_POOl_NAME) {
    return PANCAKE_ADD_LIQUIDITY_NRV_BUSD_URL
  }
  if (token) {
    symbol = symbol ?? token.symbol
  }
  if (symbol) {
    poolName = poolName ?? INVERTED_STAKING_MAP_TOKENS[symbol]
  }
  poolRouterIndex = poolRouterIndex ?? INVERTED_ROUTER_INDEX[poolName]

  return `${POOLS_PATH}/${poolRouterIndex}`
}

export function getExplorerTxUrl({ hash }) {
  return `https://bscscan.com/tx/${hash}`
}

export const NERVE_DOCS_URL = 'https://docs.nerve.fi'
export const NERVE_DISCORD_URL = 'https://discord.gg/4XEyjqJ2by'
export const NERVE_TELEGRAM_URL = 'https://t.me/nervefinance'
export const NERVE_FORUM_URL = 'https://forum.nerve.fi'
export const NERVE_TWITTER_URL = 'https://twitter.com/NerveFinance'
// Patching this as docs for now need to swap w/ git link
export const NERVE_GITHUB_URL = 'https://docs.nerve.fi/'
