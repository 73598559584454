import { POOLS_MAP, PoolName, TRANSACTION_TYPES, Token } from '@constants'
import { useAllContracts, useMetaSwapDepositContract } from './useContract'

import { BigNumber } from '@ethersproject/bignumber'
import checkAndApproveTokenForTrade from '@utils/checkAndApproveTokenForTrade'
import { getFormattedTimeString } from '@utils/dateTime'
import { subtractSlippage } from '@utils/slippage'
import { useActiveWeb3React } from '.'
import { useToasts } from 'react-toast-notifications'

import BscScanToastLink from '@components/BscScanToastLink'

export function useApproveAndMetaDeposit(poolName) {
  const swapContract = useMetaSwapDepositContract(poolName)
  const tokenContracts = useAllContracts()
  const { account } = useActiveWeb3React()
  const { addToast } = useToasts()

  const POOL_TOKENS = POOLS_MAP[poolName]
  if (!POOL_TOKENS)
    throw new Error('useApproveAndMetaDeposit requires a valid pool name')

  return async function approveAndMetaDeposit(state) {
    if (!account) throw new Error('Wallet must be connected')
    if (!swapContract) throw new Error('Swap contract is not loaded')

    const approveSingleToken = async (token) => {
      const spendingValue = BigNumber.from(
        state.tokenFormState[token.symbol].valueSafe
      )
      if (spendingValue.isZero()) return
      const tokenContract = tokenContracts?.[token.symbol]
      if (tokenContract == null) return
      await checkAndApproveTokenForTrade(
        tokenContract,
        swapContract.address,
        account,
        spendingValue,
        state.infiniteApproval,
        {
          onTransactionStart: () => {},
          onTransactionSuccess: () => {},
          onTransactionError: () => {
            throw new Error('Your transaction could not be completed')
          },
        }
      )
    }
    try {
      // For each token being deposited, check the allowance and approve it if necessary
      // await Promise.all(POOL_TOKENS.map((token) => approveSingleToken(token)))
      for (const token of POOL_TOKENS) {
        await approveSingleToken(token)
      }

      // "isFirstTransaction" check can be removed after launch
      // const poolTokenBalances = await Promise.all(
      //   POOL_TOKENS.map(async (token, i) => {
      //     return await swapContract.getTokenBalance(i)
      //   })
      // )
      // const isFirstTransaction = poolTokenBalances.every((bal) => bal.isZero())
      let minToMint
      // if (isFirstTransaction) {
      //   minToMint = BigNumber.from('0')
      // } else {
      minToMint = await swapContract.calculateTokenAmount(
        account,
        POOL_TOKENS.map(({ symbol }) => state.tokenFormState[symbol].valueSafe),
        true // deposit boolean
      )

      console.log(minToMint.toString())
      // }

      minToMint = subtractSlippage(
        minToMint,
        state.slippageSelected,
        state.slippageCustom
      )
      const clearMessage = addToast('Starting your deposit...', {
        appearance: 'info',
        autoDismiss: true,
      })

      const spendTransaction = await swapContract.addLiquidity(
        POOL_TOKENS.map(({ symbol }) => state.tokenFormState[symbol].valueSafe),
        minToMint,
        Math.round(new Date().getTime() / 1000 + 60 * 10)
      )

      console.log(spendTransaction)
      const tx = await spendTransaction.wait()

      const toastContent = (
        <>
          Liquidity added!
          <br />
          <BscScanToastLink {...tx} />
        </>
      )

      addToast(toastContent, {
        appearance: 'success',
        autoDismiss: true,
      })
      return Promise.resolve()
    } catch (e) {
      console.error(e)
      //   clearToasts()
      addToast('Unable to complete your transaction', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
}
