import TokenInput from '@components/TokenInput'
import BaseButton from '@components/BaseButton'
import PriceImpactDisplay from '@components/PriceImpactDisplay'


export default function PoolManagementDeposit({
  onChangeTokenInputValue,
  tokens,
  poolData,
  onConfirmTransaction,
  priceImpact,
}) {
  return (
    <div className="flex-col">
      {tokens.map((token, index) => (
        <div className="mt-4">
          <TokenInput
            symbol={token.symbol}
            icon={token.icon}
            max={token.max}
            inputValue={token.inputValue}
            onChange={(value) => onChangeTokenInputValue(token.symbol, value)}
            disabled={false}
          />
        </div>
      ))}
      <BaseButton
        fancy={true}
        className="w-full mt-4 text-md items-center px-6 py-3 "
        onClick={async () => {
          await onConfirmTransaction?.()
        }}
      >
        Deposit
      </BaseButton>
      <PriceImpactDisplay priceImpact={priceImpact} />
    </div>
  )
}
