import Card from '@tw/Card'

import {
  NRV,
  NRV_IMG,
  STABLE_SWAP_TOKEN,
  NRVBTC_SWAP_TOKEN,
  NRVETH_SWAP_TOKEN,
  XNERVE_TOKEN,
  NRV_BUSD_CAKE_TOKEN,
  NRV_BUSD_V2_CAKE_TOKEN,
  ANYBTC,
  ANYETH,
  ETHB,
  BTCB,
} from '@constants'

import Grid from '@tw/Grid'

import ContractListItem from './ContractListItem'

const CONTRACT_INFO = {
  NRV: {
    token: NRV,
    description: 'NRV is the base token behind nerve.fi',
    docUrl: 'https://docs.nerve.fi/tokeneconomics',
  },
  XNERVE_TOKEN: {
    token: XNERVE_TOKEN,
    description: 'xNRV is the token that is used to stake NRV ',
    docUrl: 'https://docs.nerve.fi/xnrv-staking',
  },
  NRV_BUSD_CAKE_TOKEN: {
    token: NRV_BUSD_CAKE_TOKEN,
    description: 'The Cake NRV/BUSD Liquidity pool token',
    docUrl: 'https://docs.nerve.fi/nrv-busd-cake-lp',
  },
  NRV_BUSD_V2_CAKE_TOKEN: {
    token: NRV_BUSD_V2_CAKE_TOKEN,
    description: 'The V2 Upgraded Cake NRV/BUSD Liquidity pool token',
    docUrl: 'https://docs.nerve.fi/nrv-busd-cake-lp',
  },
  STABLE_SWAP_TOKEN: {
    token: STABLE_SWAP_TOKEN,
    description: "Nerve's 3Pool stableswap LP token",
    docUrl: 'https://docs.nerve.fi/stable-swap-3pool',
  },
  NRVBTC_SWAP_TOKEN: {
    token: NRVBTC_SWAP_TOKEN,
    description: "Nerve's BTCB/anyBTC LP token",
    docUrl: undefined,
  },
  NRVETH_SWAP_TOKEN: {
    token: NRVETH_SWAP_TOKEN,
    description: "Nerve's ETH/anyETH LP token",
    docUrl: undefined,
  },
  ANYBTC: {
    token: ANYBTC,
    description: "Anyswap's anyBTC token",
    docUrl: undefined,
  },
  ANYETH: {
    token: ANYETH,
    description: "Anyswap's anyETH token",
    docUrl: undefined,
  },
  BTCB: {
    token: BTCB,
    description:
      "Binance's BTCB is a BEP-20 token that is fully backed by Bitcoin (BTC)",
    docUrl: undefined,
  },
  ETHB: {
    token: ETHB,
    description:
      "Binance's ETH is a BEP-20 token that is fully backed by Ethereum (ETH)",
    docUrl: undefined,
  },
}

export default function ContractInfoContent() {
  return (
    <Grid cols={{ xs: 1 }} gap={6}>
      <ContractGroupCard title='Tokens'>
        <ContractListItem {...CONTRACT_INFO.NRV} icon={NRV_IMG} />
        <ContractListItem {...CONTRACT_INFO.XNERVE_TOKEN} />
        <ContractListItem {...CONTRACT_INFO.NRV_BUSD_CAKE_TOKEN} />
        <ContractListItem {...CONTRACT_INFO.NRV_BUSD_V2_CAKE_TOKEN} />
        <ContractListItem {...CONTRACT_INFO.STABLE_SWAP_TOKEN} />
        <ContractListItem {...CONTRACT_INFO.NRVBTC_SWAP_TOKEN} />
        <ContractListItem {...CONTRACT_INFO.NRVETH_SWAP_TOKEN} />
      </ContractGroupCard>
      <ContractGroupCard title='Anyswap Tokens'>
        <ContractListItem {...CONTRACT_INFO.ANYBTC} />
        <ContractListItem {...CONTRACT_INFO.ANYETH} />
      </ContractGroupCard>
      <ContractGroupCard title='Binance Tokens'>
        <ContractListItem {...CONTRACT_INFO.BTCB} />
        <ContractListItem {...CONTRACT_INFO.ETHB} />
      </ContractGroupCard>
    </Grid>
  )
}

function ContractGroupCard({ title, children }) {
  return (
    <Card title={title}>
      <ul className='divide-y divide-gray-200'>{children}</ul>
    </Card>
  )
}
