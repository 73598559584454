import { STABLE_SWAP_TOKEN } from '@constants'

import { useTokenContract } from '@hooks/useContract'

import { XNERVE_POOL_NAME } from '@constants'

import Grid from '@tw/Grid'

import StakeCard from '@components/StakeCard'

import PageWrapper from '@layouts/PageWrapper'
import StandardPageContainer from '@layouts/StandardPageContainer'

import XNRVForm from './XNRVForm'


export default function XNrvPage() {
  return (
    <>
      <PageWrapper>
        <StandardPageContainer title="xNRV">
          <Grid cols={{xs: 1, md: 2}} gap={6} className="mt-6">
            <div>
              <XNRVForm />
            </div>
            <div>
              <StakeCard poolName={XNERVE_POOL_NAME} />
            </div>
          </Grid>
        </StandardPageContainer>
      </PageWrapper>
    </>
  )
}
