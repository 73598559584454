import './styles/global.css'

import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'

import React from 'react'
import ReactDOM from 'react-dom'
import { NetworkContextName } from '@constants'
import { BrowserRouter as Router } from 'react-router-dom'
import getLibrary from '@utils/getLibrary'
import Store from '@store'
import { ApolloProvider, APOLLO_CLIENT } from '@graphql'
import App from './App'
import { WATERBEAR_ASCII_ART, THE_PLAN } from '@constants/misc'


const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)
console.log(WATERBEAR_ASCII_ART)
console.log(THE_PLAN)
ReactDOM.render(
  <React.StrictMode>
    <div dangerouslySetInnerHTML={{ __html: `<!-- ${WATERBEAR_ASCII_ART} -->` }}/>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <ApolloProvider client={APOLLO_CLIENT}>
          <Store>
            <Router>
              <App />
            </Router>
          </Store>
        </ApolloProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

