import { Link } from 'react-router-dom'

import { commify } from '@ethersproject/units'

import { POOLS_MAP, INVERTED_ROUTER_INDEX } from '@constants'
import { useMetaPoolData } from '@hooks/useMetaPoolData'

import { formatBNToString } from '@utils'
import { getCardStyleByRouterIndex } from '@utils/coinStyles'
import LoadingSpinner from '@components/LoadingSpinner'
import Tooltip from '@components/Tooltip'
import CustomTooltip from '@components/CustomTooltip'
import Card from '@tw/Card'
import Grid from '@tw/Grid'

import CoinLabel from './CoinLabel'

import { getPoolUrl } from '@urls'

export default function MetaPoolsListCard({ poolName }) {
  const poolRouterIndex = INVERTED_ROUTER_INDEX[poolName]
  const coins = POOLS_MAP[poolName]

  const [poolData] = useMetaPoolData(poolName)

  const totalLockedUSD = poolData?.totalLockedUSD ? (
    commify(formatBNToString(poolData.totalLockedUSD, 18, 0))
  ) : (
    <LoadingSpinner />
  )

  const apyData = poolData?.apy ? poolData.apy : <LoadingSpinner />

  return (
    <Link to={getPoolUrl({ poolRouterIndex })}>
      <Card
        title={poolName}
        className={`py-4 mt-4 items-center pr-2 ${getCardStyleByRouterIndex(
          poolRouterIndex
        )} transition-all`}
        divider={false}
      >
        <Grid
          gap={3}
          cols={{ xs: 1, sm: 2 }}
          className='divide-x-0 sm:divide-x '
        >
          <div>
            <h3 className='text-sm text-gray-800'>Assets</h3>
            <CoinLabels coins={coins} />
          </div>
          <div>
            <StatDisplay
              className='pr-8 lg:pr-12 xl:pr-14'
              title='Total Liquidity'
              content={`$${totalLockedUSD}`}
            />
            <StatDisplay
              title='APY'
              infoTooltip={<CustomTooltip apyData={apyData} />}
              content={
                <>
                  <a href='https://appv2.rampdefi.com'>
                    Visit Ramp to stake NRV
                  </a>
                </>
              }
            />
          </div>
        </Grid>
      </Card>
    </Link>
  )
}

function StatDisplay({ className, title, infoTooltip, content }) {
  return (
    <div className={`inline-block pl-4 ${className}`}>
      <div className='text-sm text-gray-800 flex'>
        <div className='mr-1'>{title}</div>
        {infoTooltip}
      </div>
      <div className='mt-2.5 text-xl font-medium text-default'>{content}</div>
    </div>
  )
}

function CoinLabels({ coins }) {
  const maxGrid = coins.length
  let numCols = 3
  if (maxGrid < numCols) {
    numCols = maxGrid
  }
  return (
    <Grid cols={{ xs: 2, sm: 2, md: 2, lg: numCols }} gap={2} className='pt-2'>
      {coins.map((coin) => (
        <CoinLabel coin={coin} />
      ))}
    </Grid>
  )
}
