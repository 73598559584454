import classNames from 'classnames'
import chevronDownIcon from "@assets/icons/chevronDown.svg"


import { getMenuItemStyleForCoin, getMenuItemBgForCoin } from '@utils/coinStyles'

import { Menu } from '@headlessui/react'


export default function AssetDropdown({ coins, onSelectCoin, children, dropdownClassName, targetCoin}) {

  return (
    <Menu>
      { ({ open }) => (
        <div
          className="absolute bg-white border shadow-sm rounded-lg text-base focus:outline-none overflow-hidden z-10 "
        >
          <Menu.Button
            className="relative focus:outline-none text-left"
          >
            <CoinItemContent
              coin={targetCoin}
              open={open}
              expanderIcon={true}
            />
          </Menu.Button>
          { open && <hr/> }
          <Menu.Items
            as="ul"
            className="w-full mx-auto transform transition focus:outline-none"
          >
            {
              coins
                .filter((coin) => (coin.symbol != targetCoin.symbol))
                .map((coin) => (
                    <CoinItem coin={coin} onSelectCoin={onSelectCoin} />
                  )
                )
            }
          </Menu.Items>
        </div>
      )
      }
    </Menu>
  )
}


function CoinItem({ coin, onSelectCoin }) {
  return (
    <Menu.Item
      as="li"
      onClick={() => {
        onSelectCoin(coin)
      }}
    >
      {
        ({active}) => (
          <CoinItemContent
            coin={coin}
            active={active}
          />
        )
      }
    </Menu.Item>
  )
}






function CoinItemContent({coin, expanderIcon=false, open=false, active=false}) {
  return (
    <div
      className={
        classNames(
          "transition cursor-pointer px-4 py-2 w-48 sm:w-80",
          getMenuItemStyleForCoin(coin),
          { [getMenuItemBgForCoin(coin)]: active }
        )
      }
    >
      <img className="w-6 mr-2 inline" src={coin.icon} />
      <span>{coin.symbol}</span>
      { expanderIcon &&
        <img
          className={
            classNames(
              "ml-1 w-4 inline float-right mt-1.5 transform transition",
              { "rotate-180": open }
            )
          }
          src={chevronDownIcon}
        />
      }
    </div>
  )
}