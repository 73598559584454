export default function NerveLogoSvg({ className="" }) {
  return (
    <svg
      className={`w-8 ${className}`}
      viewBox="0 0 378 436"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="276.5" cy="369.5" r="14.5" fill="#4C51BF" />
      <circle cx="102.5" cy="368.5" r="14.5" fill="#4C51BF" />
      <circle cx="188.5" cy="421.5" r="14.5" fill="#4C51BF" />
      <circle cx="14.5" cy="318.5" r="14.5" fill="#4C51BF" />
      <circle cx="14.5" cy="217.5" r="14.5" fill="#4C51BF" />
      <circle cx="14.5" cy="116.5" r="14.5" fill="#4C51BF" />
      <circle cx="188.5" cy="14.5" r="14.5" fill="#4C51BF" />
      <circle cx="102.5" cy="267.5" r="17.5" fill="#4C51BF" />
      <circle cx="188.5" cy="317.5" r="17.5" fill="#4C51BF" />
      <circle cx="276.5" cy="267.5" r="17.5" fill="#4C51BF" />
      <circle cx="276.5" cy="166.5" r="17.5" fill="#4C51BF" />
      <circle cx="99.5" cy="166.5" r="17.5" fill="#4C51BF" />
      <circle cx="188.5" cy="116.5" r="17.5" fill="#4C51BF" />
      <circle cx="189.5" cy="217.5" r="22.5" fill="#4C51BF" />
      <circle cx="102.5" cy="67.5" r="14.5" fill="#4C51BF" />
      <circle cx="276.5" cy="68.5" r="14.5" fill="#4C51BF" />
      <circle cx="363.5" cy="116.5" r="14.5" fill="#4C51BF" />
      <circle cx="363.5" cy="217.5" r="14.5" fill="#4C51BF" />
      <circle cx="363.5" cy="318.5" r="14.5" fill="#4C51BF" />
      <line
        x1="25.8339"
        y1="313.903"
        x2="91.8339"
        y2="272.903"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="25.8339"
        y1="212.903"
        x2="91.8339"
        y2="171.903"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="108.892"
        y1="266.868"
        x2="179.892"
        y2="223.868"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="281.892"
        y1="365.868"
        x2="352.892"
        y2="322.868"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="207.892"
        y1="206.868"
        x2="278.892"
        y2="163.868"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="287.892"
        y1="160.868"
        x2="358.892"
        y2="117.868"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="193.892"
        y1="115.868"
        x2="264.892"
        y2="72.8678"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="113.892"
        y1="362.868"
        x2="184.892"
        y2="319.868"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="288.892"
        y1="262.868"
        x2="359.892"
        y2="219.868"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="105.23"
        y1="65.9438"
        x2="177.23"
        y2="111.944"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="105.23"
        y1="166.944"
        x2="177.23"
        y2="212.944"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="207.23"
        y1="228.944"
        x2="279.23"
        y2="274.944"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="197.23"
        y1="319.944"
        x2="269.23"
        y2="365.944"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
      <line
        x1="25.8339"
        y1="110.903"
        x2="91.8339"
        y2="69.9034"
        stroke="#4C51BF"
        strokeWidth="12"
        strokeLinejoin="round"
      />
    </svg>
  );
}
