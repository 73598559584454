import {
  BUSD,
  NRV,
  USDC,
  USDT,
  STABLE_SWAP_TOKEN,
  NRV_BUSD_CAKE_TOKEN,
  XNERVE_TOKEN,
  STABLECOIN_SWAP_ADDRESSES,
  STABLECOIN_POOL_NAME,
  MASTERMIND_ADDRESSES,
  XNERVE_CONTRACT_ADDRESSES,
  ANYETH,
  FUSDT,
  ANYBTC,
  NRVBTC_POOL_NAME,
  NRVBTC_SWAP_ADDRESSES,
  BTCB,
  NRVETH_SWAP_ADDRESSES,
  ETHB,
  NRVETH_POOL_NAME,
  NRVBTC_SWAP_TOKEN,
  NRVETH_SWAP_TOKEN,
  NRV_BUSD_V2_CAKE_TOKEN,
  NRV_BUSD_CAKE_V2_NEW_CAKE_TOKEN,
  RUSD_POOL_NAME,
  RUSD_SWAP_DEPOSIT_ADDRESSES,
  RUSD_SWAP_ADDRESSES,
  RUSD,
  NRVRUSD_ADDRESSES,
} from '@constants'
import { useMemo, useState } from 'react'
import { Contract } from '@ethersproject/contracts'
import ERC20_ABI from '@constants/abis/erc20.json'
import ANYERC20_ABI from '@constants/abis/anyErc20.json'
import ANYBTC_ABI from '@constants/abis/anyBTC.json'
import LPTOKEN_ABI from '@constants/abis/lpToken.json'
import SWAP_ABI from '@constants/abis/swap.json'
import META_SWAP_DEPOSIT_ABI from '@constants/abis/metaSwapDeposit.json'
import METASWAP_ABI from '@constants/abis/metaSwap.json'
import MASTERMIND_ABI from '@constants/abis/mastermind.json'
import MIGRATION_ABI from '@constants/abis/migration.json'
import XNERVE_ABI from '@constants/abis/xnerve.json'

import { getContract } from '@utils'
import { useActiveWeb3React } from './index'

// returns null on errors
function useContract(address, ABI, withSignerIfPossible = true) {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      )
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(t, withSignerIfPossible) {
  const { chainId } = useActiveWeb3React()
  const tokenAddress = chainId ? t.addresses[chainId] : undefined
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useAnyTokenContract(t, withSignerIfPossible) {
  const { chainId } = useActiveWeb3React()
  const tokenAddress = chainId ? t.addresses[chainId] : undefined
  const BTCABI_CONTRACT = useContract(
    tokenAddress,
    ANYBTC_ABI,
    withSignerIfPossible
  )
  const ERCABI_CONTRACT = useContract(
    tokenAddress,
    ANYERC20_ABI,
    withSignerIfPossible
  )
  if (t.symbol == 'anyBTC') {
    return BTCABI_CONTRACT
  } else {
    return ERCABI_CONTRACT
  }
}

export function useMetaSwapDepositContract(poolName) {
  const withSignerIfPossible = true
  const { chainId } = useActiveWeb3React()
  const rusdMetaSwapDepositContract = useContract(
    chainId ? RUSD_SWAP_DEPOSIT_ADDRESSES[chainId] : undefined,
    META_SWAP_DEPOSIT_ABI,
    withSignerIfPossible
  )

  return useMemo(() => {
    if (poolName === RUSD_POOL_NAME) {
      return rusdMetaSwapDepositContract
    } else {
      return null
    }
  }, [rusdMetaSwapDepositContract, poolName])
}

export function useMetaSwapContract(poolName) {
  const withSignerIfPossible = true
  const { chainId } = useActiveWeb3React()
  const rusdMetaSwapContract = useContract(
    chainId ? RUSD_SWAP_ADDRESSES[chainId] : undefined,
    METASWAP_ABI,
    withSignerIfPossible
  )

  return useMemo(() => {
    if (poolName === RUSD_POOL_NAME) {
      return rusdMetaSwapContract
    } else {
      return null
    }
  }, [rusdMetaSwapContract, poolName])
}

export function useMasterSwapContract(poolName) {
  const baseSwapContract = useSwapContract(poolName)
  const metaSwapContract = useMetaSwapContract(poolName)

  if (
    poolName === STABLECOIN_POOL_NAME ||
    poolName === NRVBTC_POOL_NAME ||
    poolName == NRVETH_POOL_NAME
  ) {
    return baseSwapContract
  }

  if (poolName === RUSD_POOL_NAME) {
    return metaSwapContract
  }
}
export function useSwapContract(poolName) {
  const withSignerIfPossible = true
  const { chainId } = useActiveWeb3React()
  const stablecoinSwapContract = useContract(
    chainId ? STABLECOIN_SWAP_ADDRESSES[chainId] : undefined,
    SWAP_ABI,
    withSignerIfPossible
  )
  const nrvbtcSwapContract = useContract(
    chainId ? NRVBTC_SWAP_ADDRESSES[chainId] : undefined,
    SWAP_ABI,
    withSignerIfPossible
  )
  const nrvethSwapContract = useContract(
    chainId ? NRVETH_SWAP_ADDRESSES[chainId] : undefined,
    SWAP_ABI,
    withSignerIfPossible
  )
  return useMemo(() => {
    if (poolName === STABLECOIN_POOL_NAME) {
      return stablecoinSwapContract
    }
    if (poolName === NRVBTC_POOL_NAME) {
      return nrvbtcSwapContract
    }
    if (poolName === NRVETH_POOL_NAME) {
      return nrvethSwapContract
    } else {
      return null
    }
  }, [stablecoinSwapContract, nrvbtcSwapContract, nrvethSwapContract, poolName])
}

export function useLPTokenContract(poolName) {
  const { chainId } = useActiveWeb3React()
  const swapContract = useSwapContract(poolName)
  let [lpTokenAddress, setLPTokenAddress] = useState('')
  void swapContract
    ?.swapStorage()
    .then(({ lpToken }) => setLPTokenAddress(lpToken))

  return useContract(lpTokenAddress, LPTOKEN_ABI)
}

export function useMetaLPTokenContract(poolName) {
  const { chainId } = useActiveWeb3React()
  const swapContract = useMasterSwapContract(poolName)
  let [lpTokenAddress, setLPTokenAddress] = useState('')
  void swapContract
    ?.swapStorage()
    .then(({ lpToken }) => setLPTokenAddress(lpToken))

  return useContract(lpTokenAddress, LPTOKEN_ABI)
}

export function useMasterMindContract() {
  const { chainId } = useActiveWeb3React()
  const masterMindContract = useContract(
    chainId ? MASTERMIND_ADDRESSES[chainId] : undefined,
    MASTERMIND_ABI
  )
  return masterMindContract
}

export function useXNerveContract() {
  const { chainId } = useActiveWeb3React()

  const xNerveContract = useContract(
    chainId ? XNERVE_CONTRACT_ADDRESSES[chainId] : undefined,
    XNERVE_ABI
  )
  return xNerveContract
}

export function useAllContracts() {
  const busdContract = useTokenContract(BUSD)
  const usdtContract = useTokenContract(USDT)
  const usdcContract = useTokenContract(USDC)
  const cakeLPContract = useTokenContract(NRV_BUSD_CAKE_TOKEN)
  const nrvContract = useTokenContract(NRV)
  const xNerveContract = useTokenContract(XNERVE_TOKEN)
  const stableSwapTokenContract = useTokenContract(STABLE_SWAP_TOKEN)
  const anybtcContract = useTokenContract(ANYBTC)
  const btcContract = useTokenContract(BTCB)
  const anyethContract = useTokenContract(ANYETH)
  const ethContract = useTokenContract(ETHB)
  const nrvethContract = useTokenContract(NRVETH_SWAP_TOKEN)
  const nrvbtcContract = useTokenContract(NRVBTC_SWAP_TOKEN)
  const cakeLPV2Contract = useTokenContract(NRV_BUSD_V2_CAKE_TOKEN)
  const cakeLPV2NewContract = useTokenContract(NRV_BUSD_CAKE_V2_NEW_CAKE_TOKEN)
  const rusdContract = useTokenContract(RUSD)

  return useMemo(() => {
    if (
      ![
        busdContract,
        usdtContract,
        usdcContract,
        stableSwapTokenContract,
        cakeLPContract,
        xNerveContract,
        nrvContract,
        anybtcContract,
        btcContract,
        anyethContract,
        ethContract,
        nrvethContract,
        nrvbtcContract,
        cakeLPV2Contract,
        cakeLPV2NewContract,
        rusdContract,
      ].some(Boolean)
    )
      return null
    return {
      [BUSD.symbol]: busdContract,
      [USDT.symbol]: usdtContract,
      [USDC.symbol]: usdcContract,
      [STABLE_SWAP_TOKEN.symbol]: stableSwapTokenContract,
      [NRV_BUSD_CAKE_TOKEN.symbol]: cakeLPContract,
      [XNERVE_TOKEN.symbol]: xNerveContract,
      [NRV.symbol]: nrvContract,
      [ANYBTC.symbol]: anybtcContract,
      [BTCB.symbol]: btcContract,
      [ANYETH.symbol]: anyethContract,
      [ETHB.symbol]: ethContract,
      [NRVETH_SWAP_TOKEN.symbol]: nrvethContract,
      [NRVBTC_SWAP_TOKEN.symbol]: nrvbtcContract,
      [NRV_BUSD_V2_CAKE_TOKEN.symbol]: cakeLPV2Contract,
      [NRV_BUSD_CAKE_V2_NEW_CAKE_TOKEN.symbol]: cakeLPV2NewContract,
      [RUSD.symbol]: rusdContract,
    }
  }, [
    busdContract,
    usdtContract,
    usdcContract,
    stableSwapTokenContract,
    cakeLPContract,
    xNerveContract,
    nrvContract,
    anybtcContract,
    btcContract,
    anyethContract,
    ethContract,
    nrvethContract,
    nrvbtcContract,
    cakeLPV2Contract,
    cakeLPV2NewContract,
    rusdContract,
  ])
}

export function useAnyContracts() {
  const anyEthContract = useAnyTokenContract(ANYETH)
  const fusdtContract = useAnyTokenContract(FUSDT)
  const anyBtcContract = useAnyTokenContract(ANYBTC)

  return useMemo(() => {
    if (![anyEthContract, fusdtContract, anyBtcContract].some(Boolean))
      return null
    return {
      [ANYETH.symbol]: anyEthContract,
      [FUSDT.symbol]: fusdtContract,
      [ANYBTC.symbol]: anyBtcContract,
    }
  }, [anyEthContract, fusdtContract, anyBtcContract])
}
