import React, { ReactElement } from 'react'
import { formatBNToPercentString, formatBNToString } from '@utils'

import { SWAPABLE_TOKENS_MAP } from '@constants'
import { commify } from '@ethersproject/units'

import InfoListItem from '@components/InfoListItem'
import InfoSection from '@components/InfoSection'


function UserPoolInfoCard({ data }) {
  if (!data) return null

  const formattedData = {
    share: formatBNToPercentString(data.share, 18, 4),
    value: commify(formatBNToString(data.value, 18, 6)),
    tokens: data.tokens.map((coin) => {
      const token = SWAPABLE_TOKENS_MAP[coin.symbol]
      return {
        symbol: token.symbol,
        name: token.name,
        value: commify(formatBNToString(coin.value, 18, 6)),
      }
    }),
  }

  return (
    <InfoSection title="My Pool LP">
      <InfoListItem labelText="Percent of Pool" content={formattedData.share}/>
      <InfoListItem labelText="Total amount:" content={formattedData.value} className="border-t"/>
    </InfoSection>
  )
}

export default UserPoolInfoCard
