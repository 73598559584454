import { POOLS_MAP } from '@constants'
import { addSlippage, subtractSlippage } from '@utils/slippage'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { useLPTokenContract, useSwapContract } from './useContract'

import { BigNumber } from '@ethersproject/bignumber'
import checkAndApproveTokenForTrade from '@utils/checkAndApproveTokenForTrade'
import { useActiveWeb3React } from '.'

export function useApproveAndWithdraw(poolName) {
  const swapContract = useSwapContract(poolName)
  const { account } = useActiveWeb3React()
  const GasPrices = {
    Standard: 'STANDARD',
    Fast: 'FAST',
    Instant: 'INSTANT',
    Custom: 'CUSTOM',
  }
  const gasStandard = 10
  const gasFast = 12
  const gasInstant = 15
  let gasCustom = 20

  const lpTokenContract = useLPTokenContract(poolName)
  const POOL_TOKENS = POOLS_MAP[poolName]
  if (!POOL_TOKENS)
    throw new Error('useApproveAndWithdraw requires a valid pool name')

  return async function approveAndWithdraw(state) {
    try {
      if (!account) throw new Error('Wallet must be connected')
      if (!swapContract) throw new Error('Swap contract is not loaded')
      if (state.lpTokenAmountToSpend.isZero()) return
      if (lpTokenContract == null) return
      const allowanceAmount =
        state.withdrawType === 'IMBALANCE'
          ? addSlippage(
              state.lpTokenAmountToSpend,
              state.slippageSelected,
              state.slippageCustom
            )
          : state.lpTokenAmountToSpend
      await checkAndApproveTokenForTrade(
        lpTokenContract,
        swapContract.address,
        account,
        allowanceAmount,
        state.infiniteApproval,
        {
          onTransactionStart: () => {},
          onTransactionSuccess: () => {},
          onTransactionError: () => {
            throw new Error('Your transaction could not be completed')
          },
        }
      )

      let gasPrice
      if (state.gasPriceSelected === GasPrices.Custom && gasCustom?.valueSafe) {
        gasPrice = gasCustom.valueSafe
      } else if (state.gasPriceSelected === GasPrices.Standard) {
        gasPrice = gasStandard
      } else if (state.gasPriceSelected === GasPrices.Instant) {
        gasPrice = gasInstant
      } else {
        gasPrice = gasFast
      }
      gasPrice = parseUnits(gasPrice?.toString() || '45', 'gwei')
      console.debug(
        `lpTokenAmountToSpend: ${formatUnits(state.lpTokenAmountToSpend, 18)}`
      )
      const inTenMinutes = Math.round(new Date().getTime() / 1000 + 60 * 10)
      let spendTransaction
      if (state.withdrawType === 'ALL') {
        spendTransaction = await swapContract.removeLiquidity(
          state.lpTokenAmountToSpend,
          POOL_TOKENS.map(({ symbol }) =>
            subtractSlippage(
              BigNumber.from(state.tokenFormState[symbol].valueSafe),
              state.slippageSelected,
              state.slippageCustom
            )
          ),
          inTenMinutes,
          {
            gasPrice,
          }
        )
      } else if (state.withdrawType === 'IMBALANCE') {
        spendTransaction = await swapContract.removeLiquidityImbalance(
          POOL_TOKENS.map(
            ({ symbol }) => state.tokenFormState[symbol].valueSafe
          ),
          addSlippage(
            state.lpTokenAmountToSpend,
            state.slippageSelected,
            state.slippageCustom
          ),
          inTenMinutes,
          {
            gasPrice,
          }
        )
      } else {
        // state.withdrawType === [TokenSymbol]
        spendTransaction = await swapContract.removeLiquidityOneToken(
          state.lpTokenAmountToSpend,
          POOL_TOKENS.findIndex(({ symbol }) => symbol === state.withdrawType),
          subtractSlippage(
            BigNumber.from(
              state.tokenFormState[state.withdrawType || ''].valueSafe
            ),
            state.slippageSelected,
            state.slippageCustom
          ),
          inTenMinutes,
          {
            gasPrice,
          }
        )
      }

      await spendTransaction.wait()
    } catch (e) {
      console.error(e)
      //   clearToasts()
    }
  }
}
