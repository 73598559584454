export const WATERBEAR_ASCII_ART = `


                         %%%%%((#%###%##((###%
                      %%%%%#(/,#(####(((((//*//%
                 &%#/#&%####((*/(((((//(///#(,/(%
               &%#(/*%####(/(/*.*/////////**/*(/(.
            %%%%##(*,*#&(((((//,,****///****/////.
         *#%%%%#(/**,,*#(((////*******//***////*(
        ###(%#(((/**,,,*(/**//************///,/(*
     /#%#%(*(##(/***************************////#
    #((%(/**((#/*************************///////(*
   #(*#((//****(*/********************,,,*///////,
  .%/*#(///*******////*******************/  /***,
  #(/*///***********//****///*****/******/
  #//**,***//**///****////***,,,,/*(///**/
 ,(/****(//////////*///////*****/     ..,.
 ,***        ((/******,,  //***(
                #/****/    ,,,
                 (***


`


export const THE_PLAN = `
A perfect plan doesn’t mean having everything go within expectations.
A perfect plan is achieved when it has the plasticity needed to flexibly deal with trouble

So don't get NeRVous now,

Project Waterbear is a go

`