import busdLogo from '@assets/icons/busd.svg'
import usdcLogo from '@assets/icons/usdc.svg'
import usdtLogo from '@assets/icons/usdt.svg'
import nerveLogo from '@assets/icons/nerve.svg'
import pancakeLogo from '@assets/icons/pancake.svg'
import ethLogo from '@assets/icons/eth.svg'
import ethBepLogo from '@assets/icons/ethbep.svg'
import btcLogo from '@assets/icons/btc.svg'
import btcBepLogo from '@assets/icons/btcbep.svg'
import anyBtcLogo from '@assets/icons/anybtc.svg'
import rampLogo from '@assets/icons/ramp.png'

export const NetworkContextName = 'NETWORK'
export const STABLECOIN_POOL_NAME = 'Stablecoin 3Pool'
export const NRVBTC_POOL_NAME = 'BTCB/anyBTC Pool'
export const NRVETH_POOL_NAME = 'ETH/anyETH Pool'
export const RUSD_POOL_NAME = 'rUSD / 3pool - External Metapool'
export const CAKE_LP_POOl_NAME = 'NRV/BUSD V1 Cake-LP Pool'
export const XNERVE_POOL_NAME = 'xNRV'
export const CAKE_LP_V2_POOl_NAME =
  'Unstake: Discontinued V2 NRV/BUSD Cake-LP Pool'
export const CAKE_LP_V2_NEW_POOl_NAME = 'New V2 NRV/BUSD Cake-LP Pool'

export const ROUTER_INDEX = {
  '3pool': STABLECOIN_POOL_NAME,
  anyBTC: NRVBTC_POOL_NAME,
  anyETH: NRVETH_POOL_NAME,
  rusd: RUSD_POOL_NAME,
}

export const INVERTED_ROUTER_INDEX = Object.fromEntries(
  Object.entries(ROUTER_INDEX).map(([k, v]) => [v, k])
)

export const ChainId = {
  ETH: 1,
  // ROPSTEN = 3,
  // RINKEBY = 4,
  MAINNET: 56,
  // GÖRLI = 5,
  // KOVAN = 42,
  HARDHAT: 31337,
}

export const BLOCK_TIME = 2500

export class Token {
  constructor(addresses, decimals, symbol, name, icon) {
    this.addresses = addresses
    this.decimals = decimals
    this.symbol = symbol
    this.name = name
    this.icon = icon
  }
}

// Stablecoin Swap
export const STABLECOIN_SWAP_ADDRESSES = {
  [ChainId.MAINNET]: '0x1B3771a66ee31180906972580adE9b81AFc5fCDc',
  [ChainId.HARDHAT]: '0x59b670e9fA9D0A427751Af201D676719a970857b',
}

export const STABLE_SWAP_TOKEN_ADDRESSES = {
  [ChainId.MAINNET]: '0xf2511b5e4fb0e5e2d123004b672ba14850478c14',
  [ChainId.HARDHAT]: '0xCe85503De9399D4dECa3c0b2bb3e9e7CFCBf9C6B',
}

export const STABLE_SWAP_TOKEN = new Token(
  STABLE_SWAP_TOKEN_ADDRESSES,
  18,
  '3NRV-LP',
  'Nerve 3Pool LP Token',
  nerveLogo
)

// Bitcoin Swap
export const NRVBTC_SWAP_ADDRESSES = {
  [ChainId.MAINNET]: '0x6C341938bB75dDe823FAAfe7f446925c66E6270c',
  [ChainId.HARDHAT]: '0x7a2088a1bFc9d81c55368AE168C2C02570cB814F',
}

export const NRVBTC_ADDRESSES = {
  [ChainId.MAINNET]: '0xD1D5Af92C606C6F2eC59D453f57A6FCc188D7dB5',
  [ChainId.HARDHAT]: '0xDC17C27Ae8bE831AF07CC38C02930007060020F4',
}

export const NRVBTC_SWAP_TOKEN = new Token(
  NRVBTC_ADDRESSES,
  18,
  'nrvBTC',
  'Nerve anyBTC/BTCB LP',
  nerveLogo
)

// ETH Swap
export const NRVETH_SWAP_ADDRESSES = {
  [ChainId.MAINNET]: '0x146CD24dCc9f4EB224DFd010c5Bf2b0D25aFA9C0',
  [ChainId.HARDHAT]: '0xc3e53F4d16Ae77Db1c982e75a937B9f60FE63690',
}

export const NRVETH_ADDRESSES = {
  [ChainId.MAINNET]: '0x0d283BF16A9bdE49cfC48d8dc050AF28b71bdD90',
  [ChainId.HARDHAT]: '0xa37aE2b259D35aF4aBdde122eC90B204323ED304',
}

export const NRVETH_SWAP_TOKEN = new Token(
  NRVETH_ADDRESSES,
  18,
  'nrvETH',
  'Nerve anyETH/ETH LP',
  nerveLogo
)

// 3Pool Stables

const BUSD_CONTRACT_ADDRESSES = {
  [ChainId.MAINNET]: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  [ChainId.HARDHAT]: '0x0B306BF915C4d645ff596e518fAf3F9669b97016',
}
export const BUSD = new Token(
  BUSD_CONTRACT_ADDRESSES,
  18,
  'BUSD',
  'Binance USD',
  busdLogo
)

const USDC_CONTRACT_ADDRESSES = {
  [ChainId.MAINNET]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  [ChainId.HARDHAT]: '0x3Aa5ebB10DC797CAC828524e59A333d0A371443c',
}

export const USDC = new Token(
  USDC_CONTRACT_ADDRESSES,
  18,
  'USDC',
  'USD Circle',
  usdcLogo
)

const USDT_CONTRACT_ADDRESSES = {
  [ChainId.MAINNET]: '0x55d398326f99059ff775485246999027b3197955',
  [ChainId.HARDHAT]: '0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE',
}

export const USDT = new Token(
  USDT_CONTRACT_ADDRESSES,
  18,
  'USDT',
  'USD Tether',
  usdtLogo
)

// RUSD Swap
export const RUSD_SWAP_ADDRESSES = {
  [ChainId.MAINNET]: '0x0eafaa7ed9866c1f08ac21dd0ef3395e910f7114',
  [ChainId.HARDHAT]: '0x7a2088a1bFc9d81c55368AE168C2C02570cB814F',
}

export const RUSD_SWAP_DEPOSIT_ADDRESSES = {
  [ChainId.MAINNET]: '0xeae67462d058148df58e20054657d864dd37cefc',
  [ChainId.HARDHAT]: '0xeae67462d058148df58e20054657d864dd37cefc',
}

export const NRVRUSD_ADDRESSES = {
  [ChainId.MAINNET]: '0x870ee4d19c12a789c61de69e3e5efb42383e4434',
  [ChainId.HARDHAT]: '0x870ee4d19c12a789c61de69e3e5efb42383e4434',
}

export const RUSD_SWAP_TOKEN = new Token(
  NRVRUSD_ADDRESSES,
  18,
  'nrvRUSD',
  'Nerve rUSD Metapool LP',
  nerveLogo
)

const RUSD_CONTRACT_ADDRESSES = {
  [ChainId.MAINNET]: '0x07663837218a003e66310a01596af4bf4e44623d',
  [ChainId.HARDHAT]: '0x07663837218a003e66310a01596af4bf4e44623d',
}

export const RUSD = new Token(
  RUSD_CONTRACT_ADDRESSES,
  18,
  'rUSD',
  'Ramp USD',
  rampLogo
)

// Nerve Contracts
const NRV_CONTRACT_ADDRESSES = {
  [ChainId.MAINNET]: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
  [ChainId.HARDHAT]: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
}

export const NRV = new Token(
  NRV_CONTRACT_ADDRESSES,
  18,
  'NRV',
  'Nerve',
  nerveLogo
)
console.log(NRV.decimals)

export const XNERVE_CONTRACT_ADDRESSES = {
  [ChainId.MAINNET]: '0x15B9462d4Eb94222a7506Bc7A25FB27a2359291e',
  [ChainId.HARDHAT]: '0x15B9462d4Eb94222a7506Bc7A25FB27a2359291e',
}

export const XNERVE_TOKEN = new Token(
  XNERVE_CONTRACT_ADDRESSES,
  18,
  'xNRV',
  'xNerve',
  nerveLogo
)

export const NRV_BUSD_CAKE_LP_TOKEN_ADDRESSES = {
  [ChainId.MAINNET]: '0x820B7d71218A74297645f0feC0B9dE85dcCa043C',
  [ChainId.HARDHAT]: '0x1613beB3B2C4f22Ee086B2b38C1476A3cE7f78E8',
}

export const NRV_BUSD_CAKE_TOKEN = new Token(
  NRV_BUSD_CAKE_LP_TOKEN_ADDRESSES,
  18,
  'Cake-LP',
  'NRV/BUSD Cake-LP',
  pancakeLogo
)

export const NRV_BUSD_CAKE_V2_LP_TOKEN_ADDRESSES = {
  [ChainId.MAINNET]: '0x3ed6d29f5d3ee3f14b7b34bf6a3366b2be1167bd',
  [ChainId.HARDHAT]: '0x3ed6d29f5d3ee3f14b7b34bf6a3366b2be1167bd',
}

export const NRV_BUSD_V2_CAKE_TOKEN = new Token(
  NRV_BUSD_CAKE_V2_LP_TOKEN_ADDRESSES,
  18,
  'Cake-LP V2',
  'NRV/BUSD Cake-LP V2',
  pancakeLogo
)

export const NRV_BUSD_CAKE_V2_NEW_LP_TOKEN_ADDRESSES = {
  [ChainId.MAINNET]: '0x401479091d0f7b8ae437ee8b054575cd33ea72bd',
  [ChainId.HARDHAT]: '0x401479091d0f7b8ae437ee8b054575cd33ea72bd',
}

export const NRV_BUSD_CAKE_V2_NEW_CAKE_TOKEN = new Token(
  NRV_BUSD_CAKE_V2_NEW_LP_TOKEN_ADDRESSES,
  18,
  'Cake-LP V2',
  'NRV/BUSD Cake-LP V2',
  pancakeLogo
)

export const MASTERMIND_ADDRESSES = {
  [ChainId.MAINNET]: '0x2EBe8CDbCB5fB8564bC45999DAb8DA264E31f24E',
  [ChainId.HARDHAT]: '0x2EBe8CDbCB5fB8564bC45999DAb8DA264E31f24E',
}

export const STABLECOIN_POOL_TOKENS = [BUSD, USDT, USDC]
export const STAKING_TOKENS = [
  STABLE_SWAP_TOKEN,
  NRV_BUSD_CAKE_TOKEN,
  XNERVE_TOKEN,
  NRVBTC_SWAP_TOKEN,
  NRVETH_SWAP_TOKEN,
  NRV_BUSD_V2_CAKE_TOKEN,
  NRV_BUSD_CAKE_V2_NEW_CAKE_TOKEN,
]

export const STAKING_TOKEN_MAP = STAKING_TOKENS.concat(STAKING_TOKENS).reduce(
  (acc, token) => ({ ...acc, [token.symbol]: token }),
  {}
)

export const POOL_FEE_PRECISION = 10

export const STAKING_MAP = {
  [STABLECOIN_POOL_NAME]: 0,
  [CAKE_LP_POOl_NAME]: 1,
  [XNERVE_POOL_NAME]: 2,
  [NRVBTC_POOL_NAME]: 4,
  [NRVETH_POOL_NAME]: 5,
  [CAKE_LP_V2_POOl_NAME]: 6,
  [CAKE_LP_V2_NEW_POOl_NAME]: 7,
}

export const STAKING_MAP_TOKENS = {
  [STABLECOIN_POOL_NAME]: STABLE_SWAP_TOKEN,
  [CAKE_LP_POOl_NAME]: NRV_BUSD_CAKE_TOKEN,
  [XNERVE_POOL_NAME]: XNERVE_TOKEN,
  [NRVBTC_POOL_NAME]: NRVBTC_SWAP_TOKEN,
  [NRVETH_POOL_NAME]: NRVETH_SWAP_TOKEN,
  [CAKE_LP_V2_POOl_NAME]: NRV_BUSD_V2_CAKE_TOKEN,
  [CAKE_LP_V2_NEW_POOl_NAME]: NRV_BUSD_CAKE_V2_NEW_CAKE_TOKEN,
}

export const INVERTED_STAKING_MAP_TOKENS = Object.fromEntries(
  Object.entries(STAKING_MAP_TOKENS).map(([k, v]) => [v.symbol, k])
)

/* putting this here as a temporary placeholder */
export const NRV_IMG =
  'https://assets.coingecko.com/coins/images/14233/small/nerve_finance_logo.png'

// Anyswap Bridge Tokens

export const ANYETH_ADDRESSES = {
  [ChainId.MAINNET]: '0x6f817a0ce8f7640add3bc0c1c2298635043c2423',
  [ChainId.HARDHAT]: '0x67d269191c92Caf3cD7723F116c85e6E9bf55933',
}

export const ANYETH = new Token(
  ANYETH_ADDRESSES,
  18,
  'anyETH',
  'Anyswap Ethereum',
  ethLogo
)

export const FUSDT_ADDRESSES = {
  [ChainId.ETH]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  [ChainId.MAINNET]: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
  [ChainId.HARDHAT]: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
}

export const FUSDT = new Token(
  FUSDT_ADDRESSES,
  6,
  'fUSDT',
  'Frapped USDT',
  usdtLogo
)

export const ANYBTC_ADDRESSES = {
  [ChainId.MAINNET]: '0x54261774905f3e6e9718f2abb10ed6555cae308a',
  [ChainId.HARDHAT]: '0xa85233C63b9Ee964Add6F2cffe00Fd84eb32338f',
}

export const ANYBTC = new Token(
  ANYBTC_ADDRESSES,
  8,
  'anyBTC',
  'Anyswap BTC',
  anyBtcLogo
)

export const BSC_BRIDGE_TOKENS = [ANYETH, ANYBTC]

export const ETH_BSC = new Token(
  ANYETH_ADDRESSES,
  18,
  'ETH',
  'Ethereum',
  ethLogo
)

export const USDT_BSC = new Token(
  FUSDT_ADDRESSES,
  6,
  'USDT',
  'USD Tether',
  usdtLogo
)

export const BTC_BSC = new Token(ANYBTC_ADDRESSES, 6, 'BTC', 'Bitcoin', btcLogo)

export const ETH_BRIDGE_TOKENS = [ETH_BSC, BTC_BSC]

export const DEPOSIT_BRIDGE_MAPPING = {
  ETH: ANYETH,
  USDT: FUSDT,
  BTC: ANYBTC,
}

export const WITHDRAW_BRIDGE_MAPPING = {
  anyETH: ETH_BSC,
  fUSDT: USDT_BSC,
  anyBTC: BTC_BSC,
}

export const BRIDGE_GOD = {
  ETH: {
    primaryAsset: ANYETH,
    bscAsset: ETH_BSC,
    nativeNetwork: {
      name: 'ETH',
    },
  },
  // USDT: {
  //   primaryAsset: FUSDT,
  //   bscAsset: USDT_BSC,
  //   nativeNetwork: {
  //     name: "ETH"
  //   }
  // },
  BTC: {
    primaryAsset: ANYBTC,
    bscAsset: BTC_BSC,
    nativeNetwork: {
      name: 'BTC',
    },
  },
}

export const BTCB_ADDRESSES = {
  [ChainId.MAINNET]: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  [ChainId.HARDHAT]: '0x4ed7c70F96B99c776995fB64377f0d4aB3B0e1C1',
}

export const BTCB = new Token(BTCB_ADDRESSES, 18, 'BTCB', 'Bitcoin', btcBepLogo)
export const BITCOIN_POOL_TOKENS = [BTCB, ANYBTC]

export const ETHB_ADDRESSES = {
  [ChainId.MAINNET]: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  [ChainId.HARDHAT]: '0x09635F643e140090A9A8Dcd712eD6285858ceBef',
}

export const ETHB = new Token(ETHB_ADDRESSES, 18, 'ETH', 'Ethereum', ethBepLogo)

export const ETH_POOL_TOKENS = [ETHB, ANYETH]

export const RUSD_POOL_TOKENS = [RUSD, BUSD, USDT, USDC]
export const RUSD_METAPOOL_TOKENS = [RUSD, STABLE_SWAP_TOKEN]

export const CAKE_TOKENS = [
  NRV_BUSD_CAKE_TOKEN,
  NRV_BUSD_V2_CAKE_TOKEN,
  NRV_BUSD_CAKE_V2_NEW_CAKE_TOKEN
]


export const POOLS_MAP = {
  [NRVETH_POOL_NAME]: ETH_POOL_TOKENS,
  [NRVBTC_POOL_NAME]: BITCOIN_POOL_TOKENS,
  [STABLECOIN_POOL_NAME]: STABLECOIN_POOL_TOKENS,
  [RUSD_POOL_NAME]: RUSD_POOL_TOKENS,
}
export const METAPOOLS_MAP = {
  [RUSD_POOL_NAME]: RUSD_METAPOOL_TOKENS,
}
export const SWAPABLE_TOKENS = [
  BUSD,
  USDT,
  USDC,
  BTCB,
  ANYBTC,
  ETHB,
  ANYETH,
  RUSD,
  STABLE_SWAP_TOKEN,
]
export const SWAPABLE_TOKENS_MAP = SWAPABLE_TOKENS.concat(
  SWAPABLE_TOKENS
).reduce((acc, token) => ({ ...acc, [token.symbol]: token }), {})

export const ALL_TOKENS = [
  NRV,
  XNERVE_TOKEN,
  ...SWAPABLE_TOKENS,
  ...STAKING_TOKENS,
]
export const ALL_TOKENS_MAP = ALL_TOKENS.concat(ALL_TOKENS).reduce(
  (acc, token) => ({ ...acc, [token.symbol]: token }),
  {}
)


export const SWAP_POOL_ARG_ID_MAP = {
  [STABLECOIN_POOL_NAME]: {
    0: BUSD,
    1: USDT,
    2: USDC,
  },
  [NRVBTC_POOL_NAME]: {
    0: BTCB,
    1: ANYBTC,
  },
  [NRVETH_POOL_NAME]: {
    0: ETHB,
    1: ANYETH,
  },
}
