import {
  NumberInputState,
  numberInputStateCreator,
} from '@utils/numberInputState'

import { BigNumber } from '@ethersproject/bignumber'
import React from 'react'
import { Token } from '@constants'

export function useTokenFormState(tokens) {
  // Token input state handlers
  const tokenInputStateCreators = tokens.reduce(
    (acc, token) => ({
      ...acc,
      [token.symbol]: numberInputStateCreator(
        token.decimals,
        BigNumber.from('0')
      ),
    }),
    {}
  )

  // Token input values, both "raw" and formatted "safe" BigNumbers
  const [tokenFormState, setTokenFormState] = React.useState(
    tokens.reduce(
      (acc, token) => ({
        ...acc,
        [token.symbol]: tokenInputStateCreators[token.symbol](''),
      }),
      {}
    )
  )


  function updateTokenFormState(newState) {
    const convertedNewState = Object.keys(newState).reduce(
      (acc, symbol) => ({
        ...acc,
        [symbol]: tokenInputStateCreators[symbol](newState[symbol]),
      }),
      {}
    )
    setTokenFormState((prevState) => ({
      ...prevState,
      ...convertedNewState,
    }))
  }

  return [tokenFormState, updateTokenFormState]
}
