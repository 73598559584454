import CloseIcon from '@components/icons/CloseIcon'

export default function CloseButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-white shadow-md hover:shadow-lg"
    >
      <span className="sr-only">Close sidebar</span>

      <CloseIcon className="h-6 w-6 text-indigo-600" />

    </button>
  )
}