import { getButtonStyleForCoin } from "@utils/coinStyles"

export default function CoinLabel({ coin }) {
  return (
    <div className="justify-self-center">
      <div className={`rounded-full border self-center py-1 px-2 ${getButtonStyleForCoin(coin)}`} style={{minWidth:96}}>
        <img alt="" className="inline-block -mt-1 w-5 mr-1" src={coin.icon}></img>
        <span className="text-md px-1 font-medium">{coin.symbol}</span>
      </div>
    </div>
  )
}