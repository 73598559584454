import {
  POOLS_MAP,
  PoolName,
  TRANSACTION_TYPES,
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STAKING_MAP_TOKENS,
  SWAPABLE_TOKENS_MAP,
  STABLE_SWAP_TOKEN,
  NRV,
  BUSD,
  NRV_BUSD_CAKE_LP_TOKEN_ADDRESSES,
  NRV_BUSD_CAKE_TOKEN,
  STAKING_MAP,
  NRVBTC_POOL_NAME,
  NRVETH_POOL_NAME,
  METAPOOLS_MAP,
} from '@constants'
import { formatBNToPercentString, getContract } from '@utils'
import {
  useAllContracts,
  useSwapContract,
  useMetaSwapContract,
  useTokenContract,
} from './useContract'
import { useEffect, useState, useContext } from 'react'
import { Context } from '../store'

import { AddressZero } from '@ethersproject/constants'
import { BigNumber } from '@ethersproject/bignumber'
import LPTOKEN_ABI from '@constants/abis/lpToken.json'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { useActiveWeb3React } from '.'
import { usePoolAPYData } from './usePoolAPYData'
import {
  usePoolTokenBalances,
  useTokenBalance,
  useTokenBalanceOfAddress,
} from './useTokenBalances'

export function useMetaPoolData(poolName) {
  const { account, library, chainId } = useActiveWeb3React()
  const swapContract = useMetaSwapContract(poolName)
  const tokenContracts = useAllContracts()
  const [poolData, setPoolData] = useState([null, null])
  const [state, dispatch] = useContext(Context)

  const poolAPYData = usePoolAPYData()

  const nrvPriceUSDPrice = state.nrvPrice
  useEffect(() => {
    async function getSwapData() {
      if (
        poolName == null ||
        swapContract == null ||
        tokenContracts == null ||
        library == null
      ) {
        return
      }

      const POOL_TOKENS = METAPOOLS_MAP[poolName]

      // Swap fees, price, and LP Token data
      const [userCurrentWithdrawFee, swapStorage] = await Promise.all([
        swapContract.calculateCurrentWithdrawFee(account || AddressZero),
        swapContract.swapStorage(),
      ])

      const {
        adminFee,
        defaultDepositFee,
        lpToken: lpToken,
        swapFee,
      } = swapStorage

      const lpTokenContract = getContract(lpToken, LPTOKEN_ABI, library)

      const [
        userLpTokenBalance,
        userLpTokenMinted,
        totalLpTokenBalance,
      ] = await Promise.all([
        lpTokenContract.balanceOf(account || AddressZero),
        lpTokenContract.balanceOf(account || AddressZero),
        lpTokenContract.totalSupply(),
      ])

      console.log(userLpTokenBalance)
      console.log(totalLpTokenBalance)

      const virtualPrice = totalLpTokenBalance.isZero()
        ? BigNumber.from(10).pow(18)
        : await swapContract.getVirtualPrice()

      // Pool token data
      const tokenBalances = await Promise.all(
        POOL_TOKENS.map(async (token, i) => {
          const balance = await swapContract.getTokenBalance(i)
          return BigNumber.from(10)
            .pow(18 - token.decimals) // cast all to 18 decimals
            .mul(balance)
        })
      )

      console.log(tokenBalances[0].toString())
      console.log(tokenBalances[1].toString())
      const tokenBalancesSum = tokenBalances.reduce((sum, b) => sum.add(b))
      let tokenBalancesUSD = tokenBalancesSum
      if (poolName == NRVBTC_POOL_NAME) {
        tokenBalancesUSD = tokenBalancesUSD?.mul(state.btcPrice)
      }
      if (poolName == NRVETH_POOL_NAME) {
        console.log(state.ethPrice)
        tokenBalancesUSD = tokenBalancesUSD?.mul(state.ethPrice)
      }
      // const poolId = STAKING_MAP[poolName]
      // const apyToken = STAKING_MAP_TOKENS[poolName]

      // const APY = await poolAPYData({
      //   poolId: poolId,
      //   poolToken: apyToken,
      //   poolTokenContract: lpTokenContract,
      //   account: account,
      // })
      // (weeksPerYear * KEEPPerWeek * KEEPPrice) / (BTCPrice * BTCInPool)
      const comparisonPoolToken = POOL_TOKENS[0]

      // User share data
      const userShare = userLpTokenBalance
        .mul(BigNumber.from(10).pow(18))
        .div(
          totalLpTokenBalance.isZero()
            ? BigNumber.from('1')
            : totalLpTokenBalance
        )
      const userPoolTokenBalances = tokenBalances.map((balance) => {
        return userShare.mul(balance).div(BigNumber.from(10).pow(18))
      })
      const userPoolTokenBalancesSum = userPoolTokenBalances.reduce((sum, b) =>
        sum.add(b)
      )

      const poolTokens = POOL_TOKENS.map((token, i) => ({
        symbol: token.symbol,
        percent: formatBNToPercentString(
          tokenBalances[i]
            .mul(10 ** 5)
            .div(
              totalLpTokenBalance.isZero()
                ? BigNumber.from('1')
                : tokenBalancesSum
            ),
          5
        ),
        value: tokenBalances[i],
      }))
      const userPoolTokens = POOL_TOKENS.map((token, i) => ({
        symbol: token.symbol,
        percent: formatBNToPercentString(
          tokenBalances[i]
            .mul(10 ** 5)
            .div(
              totalLpTokenBalance.isZero()
                ? BigNumber.from('1')
                : tokenBalancesSum
            ),
          5
        ),
        value: userPoolTokenBalances[i],
      }))

      const poolData = {
        name: poolName,
        tokens: poolTokens,
        totalLocked: tokenBalancesSum,
        totalLockedUSD: tokenBalancesUSD,
        virtualPrice: virtualPrice,
        adminFee: adminFee,
        defaultDepositFee: defaultDepositFee,
        swapFee: swapFee,
        volume: 'XXX', // TODO
        utilization: 'XXX', // TODO
        // apy: APY, // TODO
      }
      console.log(poolData)
      const userShareData = account
        ? {
            name: poolName,
            share: userShare,
            value: userPoolTokenBalancesSum,
            avgBalance: userPoolTokenBalancesSum,
            tokens: userPoolTokens,
            currentWithdrawFee: userCurrentWithdrawFee,
            lpTokenBalance: userLpTokenBalance,
            lpTokenMinted: userLpTokenMinted,
          }
        : null
      setPoolData([poolData, userShareData])
    }
    void getSwapData()
  }, [
    poolName,
    swapContract,
    tokenContracts,
    account,
    library,
    nrvPriceUSDPrice,
  ])

  return poolData
}
