import { AbstractConnector } from '@web3-react/abstract-connector'
import invariant from 'tiny-invariant'
export class RequestError extends Error {
  constructor(message, code, data) {
    super()
    this.code = code
    this.data = data
    this.name = this.constructor.name
    this.message = message
  }
}
class MiniRpcProvider {
  constructor(chainId, url) {
    this.isMetaMask = false
    this.sendAsync = (request, callback) => {
      console.log('sendAsync', request.method, request.params)
      this.request(request.method, request.params)
        .then((result) =>
          callback(null, { jsonrpc: '2.0', id: request.id, result }),
        )
        .catch((error) => callback(error, null))
    }
    this.request = async (method, params) => {
      var _a, _b, _c
      if (typeof method !== 'string') {
        params = method.params
        method = method.method
      }
      const response = await fetch(this.url, {
        method: 'POST',
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method,
          params,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok)
        throw new RequestError(
          `${response.status}: ${response.statusText}`,
          -32000,
        )
      const body = await response.json()
      if ('error' in body) {
        throw new RequestError(
          (_a = body === null || body === void 0 ? void 0 : body.error) ===
            null || _a === void 0
            ? void 0
            : _a.message,
          (_b = body === null || body === void 0 ? void 0 : body.error) ===
            null || _b === void 0
            ? void 0
            : _b.code,
          (_c = body === null || body === void 0 ? void 0 : body.error) ===
            null || _c === void 0
            ? void 0
            : _c.data,
        )
      } else if ('result' in body) {
        return body.result
      } else {
        throw new RequestError(
          `Received unexpected JSON-RPC response to ${method} request.`,
          -32000,
          body,
        )
      }
    }
    this.chainId = chainId
    this.url = url
    const parsed = new URL(url)
    this.host = parsed.host
    this.path = parsed.pathname
  }
}
export class NetworkConnector extends AbstractConnector {
  constructor({ urls, defaultChainId }) {
    invariant(
      defaultChainId || Object.keys(urls).length === 1,
      'defaultChainId is a required argument with >1 url',
    )
    super({ supportedChainIds: Object.keys(urls).map((k) => Number(k)) })
    this.currentChainId = defaultChainId || Number(Object.keys(urls)[0])
    this.providers = Object.keys(urls).reduce((accumulator, chainId) => {
      accumulator[Number(chainId)] = new MiniRpcProvider(
        Number(chainId),
        urls[Number(chainId)],
      )
      return accumulator
    }, {})
  }
  async activate() {
    return {
      provider: this.providers[this.currentChainId],
      chainId: this.currentChainId,
      account: null,
    }
  }
  async getProvider() {
    return this.providers[this.currentChainId]
  }
  async getChainId() {
    return this.currentChainId
  }
  async getAccount() {
    return null
  }
  deactivate() {
    return
  }
  changeChainId(chainId) {
    invariant(
      Object.keys(this.providers).includes(chainId.toString()),
      `No url found for chainId ${chainId}`,
    )
    this.currentChainId = chainId
    this.emitUpdate({ provider: this.providers[this.currentChainId], chainId })
  }
}
