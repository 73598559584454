// import { Deadlines, GasPrices, Slippages } from '../state/user'
import {
  PoolName,
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  SWAPABLE_TOKENS_MAP,
  TRANSACTION_TYPES,
  Token,
} from '@constants'
import { useAnyContracts } from './useContract'

import { BigNumber } from '@ethersproject/bignumber'
import { NumberInputState } from '@utils/numberInputState'
import checkAndApproveTokenForTrade from '@utils/checkAndApproveTokenForTrade'
import { getFormattedTimeString } from '@utils/dateTime'
import { parseUnits } from '@ethersproject/units'
import { subtractSlippage } from '@utils/slippage'
import { useActiveWeb3React } from '.'

export function useSwapOut(poolName) {
  const anyTokenContracts = useAnyContracts()
  const { account } = useActiveWeb3React()

  const GasPrices = {
    Standard: 'STANDARD',
    Fast: 'FAST',
    Instant: 'INSTANT',
    Custom: 'CUSTOM',
  }
  const gasStandard = 10
  const gasFast = 12
  const gasInstant = 15

  return async function swapOut(state) {
    try {
      if (!account) throw new Error('Wallet must be connected')

      // For each token being deposited, check the allowance and approve it if necessary
      const tokenContract = anyTokenContracts?.[state.fromSymbol]
      console.log(tokenContract)
      if (tokenContract == null) return
      const redeemTx = await tokenContract.Swapout(
        state.amount,
        state.toAddress
      )
      await redeemTx.wait()

      return Promise.resolve()
    } catch (err) {
      console.log(err)
    }
  }
}
