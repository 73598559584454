import _ from 'lodash'

import { useEffect, useState, useContext } from 'react'

import { AddressZero } from '@ethersproject/constants'
import { BigNumber } from '@ethersproject/bignumber'

import { useActiveWeb3React } from '@hooks'
import { usePoolAPYData } from '@hooks/usePoolAPYData'
import {
  useAllContracts,
  useSwapContract,
} from '@hooks/useContract'

import {
  POOLS_MAP,
  STAKING_MAP_TOKENS,
  STAKING_MAP,
  NRVBTC_POOL_NAME,
  NRVETH_POOL_NAME,
} from '@constants'

import LPTOKEN_ABI from '@constants/abis/lpToken.json'

import { formatBNToPercentString, getContract } from '@utils'

import { Context } from '@store'




export function usePoolData(poolName) {
  const { account, library } = useActiveWeb3React()
  const swapContract = useSwapContract(poolName)
  const tokenContracts = useAllContracts()
  const [poolData, setPoolData] = useState([null, null])
  const [state, dispatch] = useContext(Context)

  const poolAPYData = usePoolAPYData()

  const nrvPriceUSDPrice = state.nrvPrice
  const poolTokens       = POOLS_MAP[poolName]
  const poolId           = STAKING_MAP[poolName]
  const apyToken         = STAKING_MAP_TOKENS[poolName]


  async function getSwapData() {
    if (_.some([poolName, swapContract, tokenContracts, library].map(_.isNull))) {
      return
    }

    // Launch Requests upfront to save time...
    const swapStorageRequest = swapContract.swapStorage()
    const userCurrentWithdrawFeeRequest = swapContract.calculateCurrentWithdrawFee(account || AddressZero)
    const virtualPriceRequest = swapContract.getVirtualPrice()

    const rawTokenBalancesRequest = Promise.all(
      poolTokens.map((token, i) => (
        swapContract.getTokenBalance(i)
      )
      )
    )

    // Swap fees, price, and LP Token data
    const {
      adminFee,
      defaultDepositFee,
      lpToken: lpToken,
      swapFee,
    } = await swapStorageRequest

    const lpTokenContract = getContract(lpToken, LPTOKEN_ABI, library)

    const poolApyRequest = poolAPYData({
      poolId: poolId,
      poolToken: apyToken,
      poolTokenContract: lpTokenContract,
      account: account,
    })

    const [
      userLpTokenBalance,
      totalLpTokenBalance,
    ] = await Promise.all([
      lpTokenContract.balanceOf(account || AddressZero),
      lpTokenContract.totalSupply(),
    ])


    const virtualPrice = totalLpTokenBalance.isZero()
      ? BigNumber.from(10).pow(18)
      : await virtualPriceRequest

    // Pool token data
    const rawTokenBalances = await rawTokenBalancesRequest

    const tokenBalances = _.zip(poolTokens, rawTokenBalances)
      .map(([token, rawBalance]) => (
        BigNumber.from(10)
          .pow(18 - token.decimals) // cast all to 18 decimals
          .mul(rawBalance)
      )
      )


    const tokenBalancesSum = tokenBalances.reduce((sum, b) => sum.add(b))

    let tokenBalancesUSD
    if (poolName == NRVBTC_POOL_NAME) {
      tokenBalancesUSD = tokenBalancesSum?.mul(state.btcPrice)
    } else if (poolName == NRVETH_POOL_NAME) {
      tokenBalancesUSD = tokenBalancesSum?.mul(state.ethPrice)
    } else {
      tokenBalancesUSD = tokenBalancesSum
    }

    // (weeksPerYear * KEEPPerWeek * KEEPPrice) / (BTCPrice * BTCInPool)

    // User share data
    const userShare = userLpTokenBalance
      .mul(BigNumber.from(10).pow(18))
      .div(
        totalLpTokenBalance.isZero()
          ? BigNumber.from('1')
          : totalLpTokenBalance
      )
    const userPoolTokenBalances = tokenBalances.map((balance) => (
      userShare
        .mul(balance)
        .div(BigNumber.from(10).pow(18))
    )
    )

    const userPoolTokenBalancesSum = userPoolTokenBalances.reduce((sum, b) => sum.add(b))

    const sharedArgs = {
      tokenBalances,
      totalLpTokenBalance,
      tokenBalancesSum,
      poolTokens
    }

    const generalPoolTokens = getPoolTokenInfoArr({
      poolTokenBalances: tokenBalances,
      ...sharedArgs
    })

    const userPoolTokens = getPoolTokenInfoArr({
      poolTokenBalances: userPoolTokenBalances,
      ...sharedArgs
    })

    const poolData = {
      name: poolName,
      tokens: generalPoolTokens,
      totalLocked: tokenBalancesSum,
      totalLockedUSD: tokenBalancesUSD,
      virtualPrice: virtualPrice,
      adminFee: adminFee,
      defaultDepositFee: defaultDepositFee,
      swapFee: swapFee,
      volume: 'XXX', // TODO
      utilization: 'XXX', // TODO
      apy: await poolApyRequest, // TODO
    }

    let userShareData
    if (account) {
      userShareData = {
        name: poolName,
        share: userShare,
        value: userPoolTokenBalancesSum,
        avgBalance: userPoolTokenBalancesSum,
        tokens: userPoolTokens,
        currentWithdrawFee: await userCurrentWithdrawFeeRequest,
        lpTokenBalance: userLpTokenBalance,
        // the code was always doing this, i could not find out why
        lpTokenMinted: userLpTokenBalance,
      }
    } else {
      userShareData = null
    }

    setPoolData([poolData, userShareData])
  }
  useEffect(() => {
    getSwapData() // unclear wtf void does here but is ugly
  }, [
    poolName,
    swapContract,
    tokenContracts,
    account,
    library,
    nrvPriceUSDPrice,
  ])

  return poolData
}


function getPoolTokenInfoArr({ poolTokenBalances, tokenBalances , totalLpTokenBalance, tokenBalancesSum, poolTokens }){
  return (
    poolTokens.map((token, i) => ({
      symbol: token.symbol,
      percent: formatBNToPercentString(
        tokenBalances[i]
          .mul(10 ** 5)
          .div(
            totalLpTokenBalance.isZero()
              ? BigNumber.from('1')
              : tokenBalancesSum
          ),
        5
      ),
      value: poolTokenBalances[i],
    }))
  )
}
