export default function SwapIcon({ className, ...props }) {
  return (
    <svg
      className={`w-6 text-gray-800 cursor-pointer ${className ?? ""}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
      />
    </svg>
  );
}
/*
function SwapIcon() {
  return (

  )
}
<svg
            xmlns="http://www.w3.org/2000/svg"
            width="500"
            height="500"
            className="w-full h-full"
         >
            <defs>
                <clipPath id="a"><path d="M0 0h500v500H0z" /></clipPath>
                <clipPath id="b"><path d="M0 0h500v500H0z" /></clipPath>
            </defs>
            <g clipPath="url(#a)">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="#121331"
                    strokeWidth="25.2"
                    d="M430.842 337.507c-82.49.094-277.605.31-351.779.396M123.732 282.128l-56.247 56.247 56.072 56.073"
                    fill="none"
                    display="block"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="#08A88A"
                    strokeWidth="25.2"
                    d="M69.158 162.493c82.49-.094 277.605-.31 351.779-.396M376.268 217.872l56.247-56.247-56.072-56.073"
                    fill="none"
                    display="block"
                />
            </g>
        </svg>
*/
