import { useState, useEffect } from 'react'
import { useActiveWeb3React } from '@hooks'

import Card from '@tw/Card'
import Tabs from '@tw/Tabs'
import TabItem from '@tw/TabItem'

import PlusIcon from '@components/icons/PlusIcon'
import MinusIcon from '@components/icons/MinusIcon'

import DepositContent from './DepositContent'
import WithdrawContent from './WithdrawContent'

export default function BridgeCard() {
  const { account } = useActiveWeb3React()
  const [cardNav, setCardNav] = useState('deposit')

  let cardContent
  if (cardNav == 'deposit') {
    cardContent = <DepositContent />
  } else if (cardNav == 'withdraw') {
    cardContent = <WithdrawContent />
  }

  return (
    <Card className='py-2 max-w-lg rounded-xl' title={''}>
      <TabSelectorBar cardNav={cardNav} setCardNav={setCardNav} />
      {cardContent}
      {/* <BridgeComponent {...obj} /> */}
    </Card>
  )
}

function TabSelectorBar({ cardNav, setCardNav }) {
  return (
    <Tabs className='pt-2'>
      <TabItem
        isActive={cardNav == 'deposit'}
        onClick={() => {
          setCardNav('deposit')
        }}
      >
        <PlusIcon className='inline-block' />
        Deposit
      </TabItem>
      <TabItem
        isActive={cardNav == 'withdraw'}
        onClick={() => {
          setCardNav('withdraw')
        }}
      >
        <MinusIcon className='inline-block' />
        Redeem
      </TabItem>
    </Tabs>
  )
}
