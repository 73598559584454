import { useMetaSwapPoolMagic } from '@hooks/useMetaSwapPoolMagic'

import DepositDisplay from './DepositDisplay'


export default function MetaPoolManagement({ poolName }) {
  const poolMagicProps = useMetaSwapPoolMagic({ poolName })

  return (
    <DepositDisplay {...poolMagicProps}/>
  )
}
