import chevronDownIcon from "@assets/icons/chevronDown.svg"
import {
  getButtonStyleForCoin,
  getMenuItemStyleForCoin,
} from "@utils/coinStyles"

import CoinDropdown from "./CoinDropdown"

export default function CoinSelector({ targetCoin, onSelectCoin, coins }) {
  return (
    <CoinDropdown coins={coins} onSelectCoin={onSelectCoin}>
      <div
        className={
          "bg-white rounded-full px-4 py-2 border text-lg cursor-pointer focus:outline-none " +
          getButtonStyleForCoin(targetCoin) +
          " " +
          getMenuItemStyleForCoin(targetCoin)
        }
      >
        <img className="w-6 mr-2 inline" src={targetCoin.icon} />
        <span className="font-semibold inline">{targetCoin.symbol}</span>
        <img className="ml-1 w-4 inline" src={chevronDownIcon} />
      </div>
    </CoinDropdown>
  );
}
