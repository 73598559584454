
export default function InfoSection({ title, children }) {
    return (
        <>
            { title && <h3 className="text-sm mt-4 mb-1">{title}</h3>}
            <ul className="border border-gray-200 text-default rounded-md">
                {children}
            </ul>
        </>
    )
}