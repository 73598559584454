import { useActiveWeb3React } from '.'
import { getProviderOrSigner } from '@utils/'
import { BigNumber } from '@ethersproject/bignumber'
import { useTokenContract } from './useContract'

export function useTokenTransfer(token) {
  const tokenContract = useTokenContract(token)
  // console.log(tokenContract)

  return async function tokenTransfer(state) {
    await tokenContract?.transfer(state.address, state.amount)
  }
}
