import React from 'react'
import { createPopper } from '@popperjs/core'
import classNames from 'classnames'

import InfoIcon from '@components/icons/InfoIcon'
import ExternalLinkButton from './ExternalLinkButton'
export default function CustomTooltip({ apyData }) {
  const [tooltipShow, setTooltipShow] = React.useState(false)
  const btnRef = React.createRef()
  const tooltipRef = React.createRef()
  const openLeftTooltip = () => {
    createPopper(btnRef.current, tooltipRef.current, {
      placement: 'bottom',
    })
    setTooltipShow(true)
  }
  const closeLeftTooltip = () => {
    setTooltipShow(false)
  }

  return (
    <>
      <div className='flex'>
        <div className='w-full text-center'>
          <button
            type='button'
            onMouseEnter={openLeftTooltip}
            onMouseLeave={closeLeftTooltip}
            ref={btnRef}
          >
            <ExternalLinkButton href='https://appv2.rampdefi.com' />
          </button>
          <div
            className={classNames(
              'bg-gray-600 border-0 mt-3 z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg',
              {
                hidden: !tooltipShow,
                block: tooltipShow,
              }
            )}
            ref={tooltipRef}
          >
            <div>
              <div className='text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 rounded-t-lg'>
                nrvRUSD earns $RAMP. Visit Ramp here to stake nrvRUSD.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
