import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  SWAPABLE_TOKENS_MAP,
  NRVBTC_POOL_NAME,
  BITCOIN_POOL_TOKENS,
  NRVETH_POOL_NAME,
  ETH_POOL_TOKENS,
  RUSD_POOL_NAME,
  RUSD_POOL_TOKENS,
} from '@constants'

import checkAndApproveTokenForTrade from '@utils/checkAndApproveTokenForTrade'

import { parseUnits } from '@ethersproject/units'
import { subtractSlippage } from '@utils/slippage'

import {
  useAllContracts,
  useSwapContract,
  useMasterSwapContract,
} from './useContract'
import { useActiveWeb3React } from '.'

export function useApproveAndSwap(poolName) {
  const swapContract = useMasterSwapContract(poolName)
  const tokenContracts = useAllContracts()
  const { account } = useActiveWeb3React()

  const GasPrices = {
    Standard: 'STANDARD',
    Fast: 'FAST',
    Instant: 'INSTANT',
    Custom: 'CUSTOM',
  }
  const gasStandard = 5
  const gasFast = 6
  const gasInstant = 15

  let POOL_TOKENS
  if (poolName === STABLECOIN_POOL_NAME) {
    POOL_TOKENS = STABLECOIN_POOL_TOKENS
  } else if (poolName === NRVBTC_POOL_NAME) {
    POOL_TOKENS = BITCOIN_POOL_TOKENS
  } else if (poolName === NRVETH_POOL_NAME) {
    POOL_TOKENS = ETH_POOL_TOKENS
  } else if (poolName === RUSD_POOL_NAME) {
    POOL_TOKENS = RUSD_POOL_TOKENS
  } else {
    throw new Error('useApproveAndSwap requires a valid pool name')
  }

  return async function approveAndSwap(state) {
    try {
      if (!account) throw new Error('Wallet must be connected')
      if (!swapContract) throw new Error('Swap contract is not loaded')

      // For each token being deposited, check the allowance and approve it if necessary
      const tokenContract = tokenContracts?.[state.fromTokenSymbol]
      if (tokenContract == null) return
      const fromToken = SWAPABLE_TOKENS_MAP[state.fromTokenSymbol]
      await checkAndApproveTokenForTrade(
        tokenContract,
        swapContract.address,
        account,
        state.fromAmount,
        state.infiniteApproval,
        {
          onTransactionStart: () => {
            return null
            // return addToast(
            //   {
            //     type: "pending",
            //     title: `${getFormattedTimeString()} Approving spend for ${
            //       fromToken.name
            //     }`,
            //   },
            //   {
            //     autoDismiss: false, // TODO: be careful of orphan toasts on error
            //   },
            // )
          },
          onTransactionSuccess: () => {
            return null
            // return addToast({
            //   type: "success",
            //   title: `${getFormattedTimeString()} Successfully approved spend for ${
            //     fromToken.name
            //   }`,
            // })
          },
          onTransactionError: () => {
            throw new Error('Your transaction could not be completed')
          },
        }
      )

      let minToMint = state.toAmount
      console.debug(`MinToMint 1: ${minToMint.toString()}`)

      minToMint = subtractSlippage(
        minToMint,
        state.slippageSelected,
        state.slippageCustom
      )
      console.debug(`MinToMint 2: ${minToMint.toString()}`)
      let gasPrice
      // if (state.gasPriceSelected === GasPrices.Custom) {
      if (state.gasPriceSelected === 'CUSTOM') {
        gasPrice = state.gasCustom?.valueSafe
      } else if (state.gasPriceSelected === GasPrices.Fast) {
        gasPrice = gasFast
      } else if (state.gasPriceSelected === GasPrices.Instant) {
        gasPrice = gasInstant
      } else {
        gasPrice = gasStandard
      }
      gasPrice = parseUnits(String(gasPrice) || '45', 9)
      console.log(POOL_TOKENS)
      const indexFrom = POOL_TOKENS.findIndex(
        ({ symbol }) => symbol === state.fromTokenSymbol
      )
      const indexTo = POOL_TOKENS.findIndex(
        ({ symbol }) => symbol === state.toTokenSymbol
      )
      console.log(indexFrom)
      console.log(indexTo)
      if (poolName === RUSD_POOL_NAME) {
        const swapTransaction = await swapContract.swapUnderlying(
          indexFrom,
          indexTo,
          state.fromAmount,
          minToMint,
          Math.round(
            new Date().getTime() / 1000 + 60 * state.transactionDeadline
          ),
          {
            gasPrice,
          }
        )
        await swapTransaction.wait()
      } else {
        const swapTransaction = await swapContract.swap(
          indexFrom,
          indexTo,
          state.fromAmount,
          minToMint,
          Math.round(
            new Date().getTime() / 1000 + 60 * state.transactionDeadline
          ),
          {
            gasPrice,
          }
        )
        await swapTransaction.wait()
      }

      return Promise.resolve()
    } catch (err) {
      console.log(err)
    }
  }
}
