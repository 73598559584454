export default function Card({title, className, children, divider=true, ...props}) {
    let titleContent = ""
    if (title) {
        titleContent = (
            <>
                <div className="font-medium text-lg mb-2">
                    {title}
                </div>
                {divider ? <hr /> : ""}
            </>
        )
    }

    return (
        <div className={`bg-white shadow-lg pt-3 px-6 pb-6 rounded-lg ${className ?? ""}`}>
            {titleContent}
            {children}
        </div>
    )
}