import { usePoolData } from '@hooks/usePoolData'
import { useApproveAndDeposit } from '@hooks/useApproveAndDeposit'
import { useApproveAndWithdraw } from '@hooks/useApproveAndWithdraw'
import { useSwapContract } from '@hooks/useContract'
import { useWithdrawFormState } from '@hooks/useWithdrawFormState'

import { useSharedPoolMagic } from '@hooks/useSharedPoolMagic'


export function useSwapPoolMagic({ poolName }) {
  const approveAndDeposit = useApproveAndDeposit(poolName)
  const approveAndWithdraw = useApproveAndWithdraw(poolName)
  const swapContract = useSwapContract(poolName)
  const [poolData] = usePoolData(poolName)

  const [withdrawFormState, updateWithdrawFormState] = useWithdrawFormState(poolName)

  const sharedArgs = useSharedPoolMagic({
    poolName,
    poolData,
    swapContract,
    withdrawFormState,
    updateWithdrawFormState,
    approveAndDepositFunc: approveAndDeposit,
    approveAndWithdrawFunc: approveAndWithdraw
  })

  return {
    ...sharedArgs,

    poolData,
    updateWithdrawFormState,
    withdrawFormState,
  }
}
