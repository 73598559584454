import {
  PoolName,
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STAKING_TOKEN_MAP,
  TRANSACTION_TYPES,
  Token,
} from '@constants'

import { useMasterMindContract, useAllContracts } from './useContract'

// import { AppState } from '../state'

import { NumberInputState } from '@utils/numberInputState'
import checkAndApproveTokenForTrade from '@utils/checkAndApproveTokenForTrade'
import { getFormattedTimeString } from '@utils/dateTime'
import { parseUnits } from '@ethersproject/units'
import { subtractSlippage } from '@utils/slippage'
// import { updateLastTransactionTimes } from '../state/application'
import { useActiveWeb3React } from '.'
import { useToasts } from 'react-toast-notifications'

import BscScanToastLink from '@components/BscScanToastLink'

// import { useDispatch } from 'react-redux'
// import { useSelector } from 'react-redux'
// import { useToast } from './useToast'

// interface WithdrawStakeStateArgument {
//   poolId: number
//   toStakeTokenSymbol: string
//   amount: BigNumber
//   gasPriceSelected: GasPrices
//   gasCustom?: NumberInputState
// }

export function useWithdrawStake() {
  const tokenContracts = useAllContracts()
  const { account } = useActiveWeb3React()
  const { addToast } = useToasts()

  const masterMindContract = useMasterMindContract()

  return async function withdrawStake(state) {
    if (!account) throw new Error('Wallet must be connected')
    if (!masterMindContract) throw new Error('MMind contract is not loaded')

    const stakeTransaction = await masterMindContract.withdraw(
      state.poolId,
      state.amount,
    )

    const tx = await stakeTransaction.wait()

    const toastContent =
      <>
        {"Withdraw completed: "}
        <BscScanToastLink {...tx}/>
      </>


    addToast(toastContent, {
      appearance: 'success',
      autoDismiss: true,
    })
    // addToast({
    //   type: 'success',
    //   title: `${getFormattedTimeString()} Withdraw completed`,
    // })

    return Promise.resolve()
  }
}
