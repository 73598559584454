import axios from 'axios'
import { useState, useEffect, useContext } from 'react'
import { Context } from '@store'
import { useActiveWeb3React } from '@hooks'
import useGetNRVPrice from '@hooks/useGetNRVPrice'

const NRV_PRICE_URL = "https://api.coingecko.com/api/v3/simple/price?ids=nerve-finance&vs_currencies=USD"

const FALLBACK_NRV_PRICE = 1




export function useNrvPrice() {
  const { account } = useActiveWeb3React()
  const [state, dispatch] = useContext(Context)
  const [usdPrice, setUsdPrice] = useState(FALLBACK_NRV_PRICE)
  const getNrvPrice = useGetNRVPrice()

  async function effectFunc() {
    const obj = await getNrvPrice()
    const usdPrice = obj.nrvPriceUSDPrice

    setUsdPrice(usdPrice ?? FALLBACK_NRV_PRICE)
  }

  useEffect(effectFunc, [account])

  console.log( "...")
  console.log(state.nrvPrice)
  if ((state.nrvPrice == 0) || (state.nrvPrice == "")) {
    return usdPrice
  } else {
    return state.nrvPrice
  }

}
