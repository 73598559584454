import { debounce } from 'lodash'
import { useState, useCallback, useEffect } from 'react'
import { useActiveWeb3React } from '@hooks'
import {
  formatBNToPercentString,
  formatBNToString,
  calculateExchangeRate,
} from '@utils'
import { useApproveAndSwap } from '@hooks/useApproveAndSwap'
import { parseUnits } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'

import SwapIcon from '@components/icons/SwapIcon'
import BaseButton from '@components/BaseButton'
import Grid from '@tw/Grid'
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  SWAPABLE_TOKENS,
  NRVBTC_POOL_NAME,
  BITCOIN_POOL_TOKENS,
  POOLS_MAP,
} from '@constants'

import classNames from 'classnames'
import SwapForm from './SwapForm'

import { useSwapContract } from '@hooks/useContract'
import { usePoolData } from '@hooks/usePoolData'
import { usePoolTokenBalances } from '@hooks/useTokenBalances'

export default function SwapCard({
  poolName,
  coins,
  formState,
  fromCoin,
  toCoin,
  onClickReverseExchangeDirection,
  onChangeFromToken,
  onChangeToToken,
  onChangeFromAmount,
}) {
  const swapContract = useSwapContract(poolName)
  const [poolData] = usePoolData(poolName)
  const tokenBalances = usePoolTokenBalances(poolName)
  const approveAndSwap = useApproveAndSwap(poolName)

  const formattedPriceImpact = formatBNToPercentString(
    formState.priceImpact,
    18
  )
  const formattedExchangeRate = formatBNToString(formState.exchangeRate, 18, 4)

  return (
    <div className='bg-white shadow-indigo-xl hover:shadow-indigo-2xl pt-6 px-6 pb-8 rounded-xl transition-all duration-150'>
      <div>
        <SwapForm
          className=''
          isSwapFrom={true}
          tokens={coins}
          selected={fromCoin.coin}
          onChangeSelected={onChangeFromToken}
          onChangeAmount={onChangeFromAmount}
          inputValue={formState.from.value}
        />
        <Grid cols={{ xs: 1 }}>
          <div className='place-self-center'>
            <SwapIcon
              className='transform rotate-90 pb-4'
              onClick={() => onClickReverseExchangeDirection()}
            />
          </div>
        </Grid>
        <SwapForm
          isSwapFrom={false}
          tokens={coins}
          selected={toCoin.coin}
          onChangeSelected={onChangeToToken}
          inputValue={formatBNToString(
            formState.to.value,
            formState.to.coin.decimals,
            4
          )}
        />
        <BaseButton
          disabled={formState.to.value.eq(0) || formState.error}
          type='button'
          className='w-full mt-6 -ml-px px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-300 '
          onClick={async () => {
            await approveAndSwap({
              fromAmount: parseUnits(
                formState.from.value,
                formState.from.coin.decimals
              ),
              fromTokenSymbol: formState.from.coin.symbol,
              toAmount: formState.to.value,
              toTokenSymbol: formState.to.coin.symbol,
              slippageCustom: null,
              slippageSelected: 'ONE_TENTH',
              infiniteApproval: false,
              gasPriceSelected: 'STANDARD',
              gasCustom: null,
              transactionDeadline: 10,
            })
          }}
        >
          <span className=''>{formState.error == null && 'Execute Swap'}</span>
          <span className=''>{formState.error != null && formState.error}</span>
        </BaseButton>
        {!formState.exchangeRate.eq(0) && (
          <div className='exchangeRateInfo text-gray-800 text-sm pt-4'>
            <div className='flex-col'>
              <div className='flex'>
                <div>Exchange Rate: </div>
                <div className='ml-auto'>
                  {`${formState.from.coin.symbol}/${formState.to.coin.symbol}: `}
                  <span className='font-medium'>{formattedExchangeRate}</span>
                </div>
              </div>
              <div className='flex'>
                <div>Price Impact: </div>
                <div
                  className={classNames({
                    'ml-auto': true,
                    'text-red-500': formattedPriceImpact.includes('-'),
                    'text-green-500': !formattedPriceImpact.includes('-'),
                  })}
                >
                  <span className='font-medium'>{formattedPriceImpact}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
