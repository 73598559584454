import { useMemo, useState } from 'react'

import PoolManagementDeposit from '@components/PoolManagementDeposit'
import PoolManagementWithdraw from '@components/PoolManagementWithdraw'

import LiquidityManagementTabs from './LiquidityManagementTabs'


export default function DepositDisplay({
  onChangeTokenInputValue,
  tokens,
  poolData,
  onConfirmTransaction,
  priceImpact,
  updateWithdrawFormState,
  poolTokens,
  withdrawFormState,
  onConfirmWithdrawTransaction,
  estWithdrawBonus,
}) {
  const [cardNav, setCardNav] = useState('addLiquidity')

  const withdrawTokens = useMemo(
    () =>
      poolTokens.map(({ name, symbol, icon }) => ({
        name,
        symbol,
        icon,
        inputValue: withdrawFormState.tokenInputs[symbol].valueRaw,
      })),
    [withdrawFormState]
  )

  return (
    <div className='bg-white pt-6 px-6 pb-8 rounded-lg border-gray-400 border-solid border text-default'>
      <LiquidityManagementTabs
        cardNav={cardNav}
        setCardNav={setCardNav}
      />
      <div className='mt-4'>
        {cardNav == 'addLiquidity' && (
          <PoolManagementDeposit
            onChangeTokenInputValue={onChangeTokenInputValue}
            tokens={tokens}
            poolData={poolData}
            onConfirmTransaction={onConfirmTransaction}
            priceImpact={priceImpact}
          />
        )}
        {cardNav == 'removeLiquidity' && (
          <PoolManagementWithdraw
            onFormChange={updateWithdrawFormState}
            tokens={withdrawTokens}
            poolData={poolData}
            formStateData={withdrawFormState}
            onConfirmTransaction={onConfirmWithdrawTransaction}
            priceImpact={estWithdrawBonus}
          />
        )}
      </div>
    </div>
  )
}