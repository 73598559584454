import { useActiveWeb3React } from '.'
import { formatUnits } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'
import {
  NRV_BUSD_CAKE_LP_TOKEN_ADDRESSES,
  NRV,
  BUSD,
  NRV_BUSD_CAKE_V2_LP_TOKEN_ADDRESSES,
  NRV_BUSD_CAKE_V2_NEW_LP_TOKEN_ADDRESSES,
} from '@constants'
import { useTokenContract } from './useContract'

export default function useGetNRVPrice() {
  const { chainId } = useActiveWeb3React()

  const NRVContract = useTokenContract(NRV)
  const BUSDContract = useTokenContract(BUSD)

  return async function getNRVPrice() {
    let cakeNRVBalance, cakeBUSDBalance
    try {
      cakeNRVBalance = await NRVContract?.balanceOf(
        NRV_BUSD_CAKE_V2_NEW_LP_TOKEN_ADDRESSES[chainId]
      )
      cakeBUSDBalance = await BUSDContract?.balanceOf(
        NRV_BUSD_CAKE_V2_NEW_LP_TOKEN_ADDRESSES[chainId]
      )
    } catch (e) {
      console.error(e)
    }
    cakeNRVBalance = cakeNRVBalance ?? BigNumber.from(0)
    cakeBUSDBalance = cakeBUSDBalance ?? BigNumber.from(0)

    const nrvBalanceString = formatUnits(cakeNRVBalance, 'ether')
    const busdBalanceString = formatUnits(cakeBUSDBalance, 'ether')

    const busdBalanceNumber = Number(busdBalanceString)
    const nrvBalanceNumber = Number(nrvBalanceString)
    const nrvPriceUSDPrice = busdBalanceNumber / nrvBalanceNumber
    return {
      nrvPriceUSDPrice: nrvPriceUSDPrice,
      nrvBalanceNumber: nrvBalanceNumber,
      busdBalanceNumber: busdBalanceNumber,
    }
  }
}
