export function getButtonStyleForCoin(coin) {
  if (coin.symbol == 'BUSD') {
    return 'shadow-yellow-xl border-yellow-100'
  }
  if (['USDT', 'fUSDT'].includes(coin.symbol)) {
    return 'shadow-green-xl border-green-100'
  }
  if (['rUSD'].includes(coin.symbol)) {
    return 'shadow-lightblue-xl border-lightblue-100'
  }
  if (['USDC', 'ETH', 'anyETH'].includes(coin.symbol)) {
    return 'shadow-blue-xl border-blue-100'
  }
  if (['anyBTC', 'BTCB', 'BTC'].includes(coin.symbol)) {
    return 'shadow-orange-xl border-orange-100'
  }

  return 'shadow-lg border-gray-300'
}

export function getMenuItemStyleForCoin(coin) {
  if (coin.symbol == 'BUSD') {
    return 'hover:bg-yellow-50 active:bg-yellow-50'
  }
  if (['USDT', 'fUSDT'].includes(coin.symbol)) {
    return 'hover:bg-green-50 active:bg-green-50'
  }
  if (['rUSD'].includes(coin.symbol)) {
    return 'hover:bg-lightblue-50 active:bg-lightblue-50'
  }
  if (['USDC', 'ETH', 'anyETH'].includes(coin.symbol)) {
    return 'hover:bg-blue-50 active:bg-blue-50'
  }
  if (['anyBTC', 'BTCB', 'BTC'].includes(coin.symbol)) {
    return 'hover:bg-orange-50 active:bg-orange-50'
  }

  return 'hover:bg-gray-200 active:bg-gray-200'
}


export function getMenuItemBgForCoin(coin) {
  if (coin.symbol == 'BUSD') {
    return 'bg-yellow-50'
  }
  if (['USDT', 'fUSDT'].includes(coin.symbol)) {
    return 'bg-green-50'
  }
  if (['rUSD'].includes(coin.symbol)) {
    return 'bg-lightblue-50'
  }
  if (['USDC', 'ETH', 'anyETH'].includes(coin.symbol)) {
    return 'bg-blue-50'
  }
  if (['anyBTC', 'BTCB', 'BTC'].includes(coin.symbol)) {
    return 'bg-orange-50'
  }

  return 'bg-gray-200'
}

export function getCoinTextColor(coin) {
  if (coin.symbol == 'BUSD') {
    return 'text-yellow-500 group-hover:text-yellow-400'
  }
  if (['USDT', 'fUSDT'].includes(coin.symbol)) {
    return 'text-green-600 group-hover:text-green-500'
  }
  if (['rUSD'].includes(coin.symbol)) {
    return 'text-lightblue-600 group-hover:text-lightblue-500'
  }
  if (['USDC', 'ETH', 'anyETH'].includes(coin.symbol)) {
    return 'text-blue-600 group-hover:text-blue-500'
  }
  if (['anyBTC', 'BTCB', 'BTC'].includes(coin.symbol)) {
    return 'text-orange-600 group-hover:text-orange-500'
  }

  return 'text-indigo-600 group-hover:text-indigo-500'
}

export function getCardStyleByRouterIndex(poolRouterIndex) {
  if (poolRouterIndex == '3pool') {
    return 'shadow-lg hover:shadow-2xl'
  }
  if (poolRouterIndex == 'anyETH') {
    return 'shadow-blue-lg hover:shadow-blue-2xl'
  }
  if (poolRouterIndex == 'anyBTC') {
    return 'shadow-orange-lg hover:shadow-orange-2xl'
  }
  if (poolRouterIndex == 'rusd') {
    return 'shadow-lightblue-lg hover:shadow-lightblue-2xl'
  }

  return 'shadow-lg hover:shadow-2xl'
}
