import {
  STABLECOIN_POOL_NAME,
  NRVBTC_POOL_NAME,
  NRVETH_POOL_NAME,
  RUSD_POOL_NAME,
} from '@constants'

import PageWrapper from '@layouts/PageWrapper'
import StandardPageContainer from '@layouts/StandardPageContainer'

import PoolsListCard from './PoolsListCard'
import MetaPoolsListCard from './MetaPoolsListCard'

export default function PoolsPage() {
  return (
    <PageWrapper>
      <StandardPageContainer title='Pools'>
        <PoolsListCard poolName={STABLECOIN_POOL_NAME} />
        <PoolsListCard poolName={NRVBTC_POOL_NAME} />
        <PoolsListCard poolName={NRVETH_POOL_NAME} />
        <MetaPoolsListCard poolName={RUSD_POOL_NAME} />
      </StandardPageContainer>
    </PageWrapper>
  )
}
