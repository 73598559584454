const baseClassname = `
  text-white
  px-2 py-2 rounded-md
  transition-all duration-75
  focus:outline-none
  `
const fancyBgClassname = `
  bg-gradient-to-r from-purple-600 to-blue-600
  hover:from-purple-700 hover:to-blue-700
  active:from-purple-800 active:to-blue-800
  disabled:from-coolGray-300 disabled:to-coolGray-200
  `

const bgClassname = `bg-indigo-600 hover:bg-indigo-800 active:bg-indigo-900 disabled:bg-coolGray-300`

export default function BaseButton({ className, children, fancy, ...props}) {
  return (
    <button
      className={`${baseClassname} ${fancy ? fancyBgClassname : bgClassname} ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}