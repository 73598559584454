
import {
  ROUTER_INDEX,
  POOLS_MAP,
} from '@constants'
import { POOLS_PATH } from '@urls'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import PageWrapper from '@layouts/PageWrapper'

import { usePoolData } from '@hooks/usePoolData'
import { useMetaPoolData } from '@hooks/useMetaPoolData'
import LoadingSpinner from '@components/LoadingSpinner'
import HomeIcon from '@components/icons/HomeIcon'
import ExternalLinkButton from '@components/ExternalLinkButton'

import Card from '@tw/Card'
import Grid from '@tw/Grid'
import Col from '@tw/Col'

import PoolInfoCard from './PoolInfoCard'
import PoolManagement from './PoolManagement'
import MetaPoolManagement from './MetaPoolManagement'


export default function Pool({ match }) {
  const {
    params: { id },
  } = match
  const poolName = ROUTER_INDEX[id]
  const coins = POOLS_MAP[poolName]
  const [poolData, userShareData] = usePoolData(poolName)
  const [metaPoolData, metaUserShareData] = useMetaPoolData(poolName)

  const apyData = poolData?.apy ?? <LoadingSpinner />

  let infoCardProps
  let infoTitle
  let PoolManagementComponent
  if (id == 'rusd') {
    infoCardProps = {
      data: metaPoolData,
      userData: metaUserShareData
    }
    infoTitle = "Visit Ramp to stake nrvRUSD "
    PoolManagementComponent = MetaPoolManagement
  } else {
    infoCardProps = {
      data: poolData,
      userData: userShareData
    }
    infoTitle = `${poolName} APY: ${apyData.fullCompoundedAPY}%`
    PoolManagementComponent = PoolManagement
  }


  return (
    <PageWrapper>
      <main className='flex-1 relative z-0 overflow-y-auto focus:outline-none'>
        <div className='mt-4 mx-auto px-12 py-8'>
          <nav className='flex' aria-label='Breadcrumb'>
            <ol className='flex items-center space-x-4'>
              <PoolBreadcrumb
                label={
                  <>
                    <HomeIcon className="h-5 w-5"/>
                    <span className='sr-only'>Home</span>
                  </>
                }
                to="/"
                showIcon={false}
              />
              <PoolBreadcrumb
                label="Pools"
                to={POOLS_PATH}
              />
              <PoolBreadcrumb
                label={poolName}
                to='#'
              />
            </ol>
          </nav>
          <Card className=' my-8 px-10 pt-4'>
            <div className='inline-flex items-center'>
              {coins.map((coin) => (
                <img
                  className='relative -ml-4 inline-block text-white shadow-solid w-10'
                  src={coin.icon}
                />
              ))}
              <h3 className='text-2xl text-default ml-2'>
                {poolName}
              </h3>
            </div>
            <Grid
              gap={4}
              cols={{xs: 1, sm: 2 }}
            >
              <Col xs={1} >
                <PoolInfoCard {...infoCardProps} />
              </Col>
              <Col xs={1} >
                <div className='mb-2  items-center'>
                  <h2 className='text-lg text-default font-medium'>
                    {infoTitle}
                    <ExternalLinkButton href='https://appv2.rampdefi.com' />
                  </h2>
                  {/* <Tooltip apyData={apyData} /> */}
                </div>
                <PoolManagementComponent poolName={poolName} />
              </Col>
            </Grid>
          </Card>
        </div>
      </main>
    </PageWrapper>
  )
}


function PoolBreadcrumb({label, to, showIcon=true}) {
  return (
    <li>
      <div className='flex items-center'>
        { showIcon &&
          <svg
            className='flex-shrink-0 h-5 w-5 text-gray-300'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
            aria-hidden='true'
          >
            <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
          </svg>
        }
        <Link
          to={to}
          className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
        >
          {label}
        </Link>
      </div>
    </li>
  )
}

