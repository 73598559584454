import { getMenuItemStyleForCoin } from '@utils/coinStyles'

import { Menu } from '@headlessui/react'

export default function CoinDropdown({ coins, onSelectCoin, children}) {
  return (
    <Menu>
      <Menu.Button className="focus:outline-none">
        {children}
      </Menu.Button>
      <Menu.Items className="absolute bg-white shadow-lg border border-solid border-gray-200 rounded-md text-base focus:outline-none my-1 overflow-hidden">
        {coins.map((coin) => (
          <Menu.Item
            className={
              'cursor-pointer px-4 py-2 transition ' +
              getMenuItemStyleForCoin(coin)
            }
            onClick={() => {
              onSelectCoin(coin)
            }}
          >
            <div >
              <img className="w-6 mr-2 inline" src={coin.icon} />
              <span>{coin.symbol}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>

  )
}