import { Route, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import Web3ReactManager from '@components/Web3ReactManager'

import Swap from '@pages/Swap'
import Pools from '@pages/Pools'
import Pool from '@pages/Pool'
import Stake from '@pages/Stake'
import XNrv from '@pages/XNrv'
import Bridge from '@pages/Bridge'
import ContractInfo from '@pages/ContractInfo'
import Statistics from '@pages/Statistics'
import Portfolio from '@pages/Portfolio'

import Home from '@pages/Home'

import {
  POOLS_PATH,
  BRIDGE_PATH,
  STAKE_PATH,
  XNRV_STAKING_PATH,
  CONTRACTS_PATH,
  PORTFOLIO_PATH,
  STATISTICS_PATH,
} from '@urls'

export default function App() {
  return (
    <Web3ReactManager>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}
        // components={{ Toast: Snack }}
        placement="top-right"
      >
        <Switch>
          <Route exact path="/" component={Swap} />
          <Route exact path={BRIDGE_PATH} component={Bridge} />
          <Route exact path={POOLS_PATH} component={Pools} />
          <Route path={`${POOLS_PATH}/:id`} component={Pool} />
          <Route path={STAKE_PATH} component={Stake} />
          <Route path={XNRV_STAKING_PATH} component={XNrv} />
          <Route path={CONTRACTS_PATH} component={ContractInfo} />
          <Route path={PORTFOLIO_PATH} component={Portfolio} />
          <Route path={STATISTICS_PATH} component={Statistics} />
          <Route path="/home" component={Home} />
        </Switch>
      </ToastProvider>
    </Web3ReactManager>
  )
}

