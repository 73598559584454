import ethBepLogo from '@assets/icons/ethbep.svg'
import btcLogo from '@assets/icons/btc.svg'

import {
  STABLECOIN_SWAP_ADDRESSES,
  NRVBTC_SWAP_ADDRESSES,
  NRVETH_SWAP_ADDRESSES,
  STABLECOIN_POOL_NAME,
  NRVBTC_POOL_NAME,
  NRVETH_POOL_NAME
} from '@constants'

import Grid from '@tw/Grid'


import SmartContractEventTableCard from './SmartContractEventTableCard'




export default function StatisticsContent() {
  return (
    <Grid gap={4} cols={{xs: 1}}>
      <SmartContractEventTableCard
        poolName={STABLECOIN_POOL_NAME}
        swapAddresses={STABLECOIN_SWAP_ADDRESSES}
        valueLabel="($)"
      />
      <SmartContractEventTableCard
        poolName={NRVBTC_POOL_NAME}
        swapAddresses={NRVBTC_SWAP_ADDRESSES}
        valueLabel={<img src={btcLogo} className='w-4 h-4 inline'/> }
      />
      <SmartContractEventTableCard
        poolName={NRVETH_POOL_NAME}
        swapAddresses={NRVETH_SWAP_ADDRESSES}
        valueLabel={<img src={ethBepLogo} className='w-4 h-4 inline' />}
      />
    </Grid>
  )
}

