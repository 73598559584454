import React from 'react'
import { createPopper } from '@popperjs/core'
import classNames from 'classnames'

import InfoIcon from '@components/icons/InfoIcon'

export default function Tooltip({ apyData }) {
  const [tooltipShow, setTooltipShow] = React.useState(false)
  const btnRef = React.createRef()
  const tooltipRef = React.createRef()
  const openLeftTooltip = () => {
    createPopper(btnRef.current, tooltipRef.current, {
      placement: 'bottom',
    })
    setTooltipShow(true)
  }
  const closeLeftTooltip = () => {
    setTooltipShow(false)
  }

  return (
    <>
      <div className="flex">
        <div className="w-full text-center">
          <button
            type="button"
            onMouseEnter={openLeftTooltip}
            onMouseLeave={closeLeftTooltip}
            ref={btnRef}
          >
            <InfoIcon/>
          </button>
          <div
            className={
              classNames('bg-gray-600 border-0 mt-3 z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg',
                {
                  'hidden': !tooltipShow,
                  'block': tooltipShow,
                }
              )
            }
            ref={tooltipRef}
          >
            <div>
              <div
                className="text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 rounded-t-lg"
              >
                2/3 of rewards vest for 6 months
              </div>
              <div className="text-white p-3">
                <div>
                  Daily APR: {Math.round((apyData.weeklyAPR / 7) * 100) / 100}%
                </div>
                <div>
                  Weekly APR: {apyData.weeklyAPR}% <br />
                </div>
                <div>
                  Yearly APR: {Math.round(apyData.weeklyAPR * 52 * 100) / 100}%
                </div>
                <div>Yearly APY: {apyData.fullCompoundedAPY}% </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

