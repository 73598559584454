import { NavLink } from 'react-router-dom'


export default function SidebarNavLink({ IconComponent, labelText, to }) {
  const navLinkClassname = ` text-default hover:bg-indigo-300 hover:bg-opacity-75
    group flex items-center px-2 py-2 text-sm font-medium rounded-md
    `

  return (
    <NavLink
      exact
      to={to}
      className={navLinkClassname}
      activeClassName=" bg-indigo-100 "
    >
      <IconComponent className="mr-3 text-indigo-500 group-hover:text-indigo-600"/>
      {labelText}
    </NavLink>
  )
}
