import PageWrapper from '@layouts/PageWrapper'
import StandardPageContainer from '@layouts/StandardPageContainer'

import StatisticsContent from './StatisticsContent'



export default function Statistics() {
  return (
    <PageWrapper>
      <StandardPageContainer title="Statistics">
        <div className="mt-4">
          <StatisticsContent />
        </div>
      </StandardPageContainer>
    </PageWrapper>
  )
}
