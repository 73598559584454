import classNames from 'classnames'
import { commify } from '@ethersproject/units'

import { formatBNToPercentString, formatBNToString } from '@utils'

import { POOL_FEE_PRECISION, SWAPABLE_TOKENS_MAP } from '@constants'

import InfoListItem from '@components/InfoListItem'
import InfoSection from '@components/InfoSection'

import UserPoolInfoCard from './UserPoolInfoCard'


export default function PoolInfoCard({ data, userData }) {
  const swapFee           = bnPercentFormat(data?.swapFee)
  const adminFee          = bnPercentFormat(data?.adminFee)
  const defaultDepositFee = bnPercentFormat(data?.defaultDepositFee)

  const formattedData = {
    name: data?.name,
    swapFee,
    defaultDepositFee: defaultDepositFee,
    virtualPrice:      data?.virtualPrice ? commifyBnToString(data.virtualPrice, 5): null,
    totalLocked:       commifyBnWithDefault(data?.totalLocked),
    totalLockedUSD:    commifyBnWithDefault(data?.totalLockedUSD),
    reserve:           commifyBnWithDefault(data?.reserve),
    adminFee:          swapFee && adminFee ? `${adminFee} of ${swapFee}`: null,
    volume:            data?.volume,
    tokens:
      data?.tokens.map((coin) => {
        const token = SWAPABLE_TOKENS_MAP[coin.symbol]
        return {
          symbol: token.symbol,
          name: token.name,
          icon: token.icon,
          percent: coin.percent,
          value: commifyBnToString(coin.value),
        }
      }) || [],
  }

  return (
    <div className='mt-2 text-gray-600'>
      <UserPoolInfoCard data={userData} />
      <InfoSection title='Pool Info'>
        <InfoListItem
          labelText='Trading Fee:'
          content={formattedData.swapFee}
        />
        <InfoListItem
          labelText='Deposit Fee:'
          content={formattedData.defaultDepositFee}
          className='border-t'
        />
        <InfoListItem
          labelText='Virtual Price:'
          content={formattedData.virtualPrice}
          className='border-t'
        />
        <InfoListItem
          labelText='Total Liquidity:'
          content={formattedData.totalLocked}
          className='border-t'
        />
        <InfoListItem
          labelText='Total Liquidity USD:'
          content={'$' + formattedData.totalLockedUSD}
          className='border-t'
        />
      </InfoSection>
      <InfoSection title='Currency Reserves'>
        {formattedData.tokens.map((token, index) => (
          <InfoListItem
            labelText={
              <div className='inline-flex items-center'>
                <img
                  className='relative text-white shadow-solid w-6 mr-2'
                  src={token.icon}
                ></img>
                <div>
                  {token.symbol} {token.percent}:
                </div>
              </div>
            }
            content={token.value}
            className={classNames({ 'border-t': index != 0 })}
          />
        ))}
      </InfoSection>
    </div>
  )
}


function commifyBnToString(value, decimals=2) {
  return (
    commify(formatBNToString(value, 18, decimals))
  )
}


function commifyBnWithDefault(value) {
  return (value ? commifyBnToString(value) : '0')
}

function bnPercentFormat(value) {
  return (
    value ? formatBNToPercentString(value, POOL_FEE_PRECISION) : null
  )
}