import React, { useRef } from 'react'
import { SWAPABLE_TOKENS_MAP } from '@constants'

import { formatBNToString } from '@utils'
import { BigNumber } from '@ethersproject/bignumber'
import { useTokenBalance } from '@hooks/useTokenBalances'
import classNames from 'classnames'

import CoinDropdown from './CoinDropdown'

export default function SwapForm({
  tokens,
  selected,
  inputValue,
  isSwapFrom,
  onChangeSelected,
  onChangeAmount,
}) {
  const ref = useRef(null)
  // TODO: Fix later
  const tokenBalance =
    useTokenBalance(SWAPABLE_TOKENS_MAP[selected.symbol]) ?? BigNumber.from(0)
  const formattedBalance = formatBNToString(tokenBalance, selected.decimals, 4)

  function onClick() {
    ref?.current?.focus()
  }

  return (
    <div
      onClick={onClick}
      className={classNames({
        'flex-col': true,
        'py-4': true,
        'px-4': true,
        'rounded-lg': true,
        'bg-lightblue-50': !isSwapFrom,
        'focus-within:bg-lightblue-100': true,
      })}
    >
      <div>
        <h3 className='text-xl'>{isSwapFrom ? 'Swap from' : ' Swap to'}</h3>
        <div className='float-right'>
          <h3
            className='ml-auto text-xs cursor-pointer self-center underline text-default -mt-5'
            onClick={() => {
              onChangeAmount(formattedBalance) // unclear why using truncated bal vs true max bal
            }}
          >
            {isSwapFrom && `Max: ${formattedBalance}`}
          </h3>
        </div>
      </div>
      <div className='my-4'>
        <div className='inline-block items-center'>
          <CoinDropdown coins={tokens} onSelectCoin={onChangeSelected}>
            <img
              className='w-6 mr-4 inline-block -mt-8'
              src={selected.icon}
            ></img>
            <div className='inline-block '>
              <div className='text-2xl font-medium -ml-4'>
                {selected.symbol}
              </div>
              <div className='text-sm text-gray-500'>{selected.name}</div>
            </div>
          </CoinDropdown>
        </div>
        <input
          ref={ref}
          className={classNames(
            'ml-auto',
            'mr-2',
            'text-right',
            'focus:outline-none',
            'bg-transparent',
            'inline-block',
            'float-right'
          )}
          placeholder='0.0'
          onChange={(e) => onChangeAmount?.(e.target.value)}
          value={!isSwapFrom && inputValue == 0 ? '' : inputValue}
        />
      </div>
    </div>
  )
}
