export default function StandardPageContainer({ title, subtitle, children }) {
  return (
    <main className="flex-1 relative z-0 overflow-y-auto h-full focus:outline-none">
      <div className="2xl:w-3/4 items-center mt-4 sm:mt-6 mx-auto px-4 sm:px-8 md:px-12 py-8">
        <h3 className="text-3xl font-medium text-default">
          {title}
        </h3>
        <div className="text-sm font-medium text-gray-500 mt-1">
          {subtitle ?? ""}
        </div>
        {children}
      </div>
    </main>
  );
}
