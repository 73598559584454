import {
  STABLECOIN_POOL_NAME,
  CAKE_LP_POOl_NAME,
  NRVBTC_POOL_NAME,
  NRVETH_POOL_NAME,
  CAKE_LP_V2_POOl_NAME,
  CAKE_LP_V2_NEW_POOl_NAME,
} from '@constants'

import Grid from '@tw/Grid'

import PageWrapper from '@layouts/PageWrapper'
import StandardPageContainer from '@layouts/StandardPageContainer'

import PoolStakeCard from './PoolStakeCard'

export default function Stake() {
  return (
    <PageWrapper>
      <StandardPageContainer title='Stake'>
        {/* <div className='bg-indigo-600 shadow-lg text-white px-8 py-4 rounded-md mt-2 text'>
          <span className='font-medium text-lg'>
            Pancake Swap has successfully upgraded to V2!
          </span>
          <br />
          We need to upgrade the NRV/BUSD Cake-LP tokens to V2.
          <br />
          <br />
          <h1 className='font-semibold text-xl'>Steps to take:</h1>
          <li>Unstake from the V1 Pool.</li>
          <li>
            Visit the{' '}
            <a
              className='underline'
              target='blank'
              href='https://v1exchange.pancakeswap.finance/#/migrate'
            >
              PancakeSwap migration page here
            </a>
          </li>
          <li>Re-stake newly received Cake-LP tokens in the V2 pool.</li>
          <li>
            Join our{' '}
            <a
              className='underline'
              target='blank'
              href='https://t.me/nervefinance'
            >
              Telegram
            </a>{' '}
            or{' '}
            <a
              className='underline'
              target='blank'
              href='https://discord.gg/nervefinance'
            >
              Discord
            </a>{' '}
            for help
          </li>
        </div> */}

        <Grid cols={{ xs: 1, sm: 1, md: 2 }} gap={6} className='mt-6'>
          <PoolStakeCard poolName={STABLECOIN_POOL_NAME} />
          <PoolStakeCard poolName={CAKE_LP_V2_NEW_POOl_NAME} />
          <PoolStakeCard poolName={NRVBTC_POOL_NAME} />
          <PoolStakeCard poolName={NRVETH_POOL_NAME} />
          <PoolStakeCard poolName={CAKE_LP_POOl_NAME} />
          <PoolStakeCard poolName={CAKE_LP_V2_POOl_NAME} />
        </Grid>
      </StandardPageContainer>
    </PageWrapper>
  )
}
