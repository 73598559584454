import SwapIcon from '@components/icons/SwapIcon'
import CoinSelector from './CoinSelector'

export default function CoinSwapper({
  formState,
  coins,
  onSelectFromCoin,
  onSelectToCoin,
  swapFromToCoins,
}) {
  return (
    <div className='space-x-4 my-5'>
      <div className='inline-block'>
        <CoinSelector
          onSelectCoin={onSelectFromCoin}
          targetCoin={formState.from.coin}
          coins={coins}
        />
      </div>
      <div className='inline-block'>
        <div
          className='cursor-pointer inline'
          onClick={() => {
            swapFromToCoins()
          }}
        >
          <SwapIcon className='' />
        </div>
      </div>
      <div className='inline-block'>
        <CoinSelector
          onSelectCoin={onSelectToCoin}
          targetCoin={formState.to.coin}
          coins={coins}
        />
      </div>
    </div>
  )
}
