import {
  BLOCK_TIME,
  NRVBTC_POOL_NAME,
  NRVETH_POOL_NAME,
  RUSD_POOL_NAME,
  Token,
} from '@constants'
import {
  BUSD,
  PoolName,
  STABLECOIN_POOL_NAME,
  USDC,
  USDT,
  ANYBTC,
  BTCB,
  ANYETH,
  ETHB,
  RUSD,
} from '@constants'

import { BigNumber } from '@ethersproject/bignumber'
import { useActiveWeb3React } from './index'
import usePoller from './usePoller'
import { useMemo, useState } from 'react'
import {
  useMasterMindContract,
  useMasterMindContractV2,
  useTokenContract,
} from './useContract'

export function useTokenBalance(t) {
  const { account, chainId } = useActiveWeb3React()
  const [balance, setBalance] = useState(BigNumber.from(0))

  const tokenContract = useTokenContract(t)

  usePoller(() => {
    async function pollBalance() {
      const newBalance =
        (await tokenContract?.balanceOf(account)) ?? BigNumber.from(0)
      if (newBalance !== balance) {
        setBalance(newBalance)
      }
    }
    if (account && chainId) {
      void pollBalance()
    }
  }, BLOCK_TIME)

  return balance
}

export function useTokenBalanceOfAddress(t, address) {
  const { account, chainId } = useActiveWeb3React()
  const [balance, setBalance] = useState(BigNumber.from(0))

  const tokenContract = useTokenContract(t)
  // tokenContract?.balanceOf(address).then((newBalance) => {
  //   if (newBalance !== balance) {
  //     setBalance(newBalance)
  //   }
  // })
  //     if (newBalance !== balance) {
  //       setBalance(newBalance)
  //     }
  usePoller(() => {
    async function pollBalance() {
      console.log('in poll')
      const newBalance = await tokenContract?.balanceOf(address)
      if (newBalance !== balance) {
        setBalance(newBalance)
      }
    }
    if (account && chainId) {
      void pollBalance()
    }
  }, BLOCK_TIME)

  return balance
}

export function useStakedBalance(poolId) {
  const { account, chainId } = useActiveWeb3React()

  const [balance, setBalance] = useState({
    amount: BigNumber.from(0),
    reward: BigNumber.from(0),
  })

  const masterMindContract = useMasterMindContract()

  usePoller(() => {
    async function pollBalance() {
      // TODO: Fix Later
      const newAmount = (await masterMindContract?.userInfo(
        poolId,
        account
      )) ?? { amount: BigNumber.from(0) }
      const newReward =
        (await masterMindContract?.pendingNerve(poolId, account)) ??
        BigNumber.from(0)
      if (newAmount.amount !== balance.amount && newReward != balance.reward) {
        setBalance({
          amount: newAmount.amount,
          reward: newReward,
        })
      }
    }
    if (account && chainId) {
      void pollBalance()
    }
  }, BLOCK_TIME)

  return balance
}

export function usePoolTokenBalances(poolName) {
  const busdTokenBalance = useTokenBalance(BUSD)
  const usdtTokenBalance = useTokenBalance(USDT)
  const usdcTokenBalance = useTokenBalance(USDC)
  const anybtcTokenBalance = useTokenBalance(ANYBTC)
  const btcbTokenBalance = useTokenBalance(BTCB)
  const anyethTokenBalance = useTokenBalance(ANYETH)
  const ethbTokenBalance = useTokenBalance(ETHB)
  const rusdTokenBalance = useTokenBalance(RUSD)

  const stablePoolTokenBalances = useMemo(
    () => ({
      [BUSD.symbol]: busdTokenBalance,
      [USDT.symbol]: usdtTokenBalance,
      [USDC.symbol]: usdcTokenBalance,
    }),
    [busdTokenBalance, usdtTokenBalance, usdcTokenBalance]
  )

  const btcPoolTokenBalances = useMemo(
    () => ({
      [BTCB.symbol]: btcbTokenBalance,
      [ANYBTC.symbol]: anybtcTokenBalance,
    }),
    [btcbTokenBalance, anybtcTokenBalance]
  )
  const ethPoolTokenBalances = useMemo(
    () => ({
      [ETHB.symbol]: ethbTokenBalance,
      [ANYETH.symbol]: anyethTokenBalance,
    }),
    [ethbTokenBalance, anyethTokenBalance]
  )
  const rusdTokenBalances = useMemo(
    () => ({
      [BUSD.symbol]: busdTokenBalance,
      [USDT.symbol]: usdtTokenBalance,
      [USDC.symbol]: usdcTokenBalance,
      [RUSD.symbol]: rusdTokenBalance,
    }),
    [busdTokenBalance, usdtTokenBalance, usdcTokenBalance, rusdTokenBalance]
  )

  if (poolName === STABLECOIN_POOL_NAME) {
    return stablePoolTokenBalances
  }
  if (poolName === NRVBTC_POOL_NAME) {
    return btcPoolTokenBalances
  }
  if (poolName === NRVETH_POOL_NAME) {
    return ethPoolTokenBalances
  }
  if (poolName === RUSD_POOL_NAME) {
    return rusdTokenBalances
  }
  return null
}
